import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import VerificationCodeModal from "../Helper/VerificationCodeModal";
import { connect } from "react-redux";
import {
  userLoginNftCreateStart,
} from "../store/actions/UserAction";

const LandingPageIndex = (props) => {
  const [show, setShow] = useState(false);
  const [route, setRoute] = useState('dashboard');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [createTokenInput, setCreateTokenInput] = useState({
    contract_name: "",
    symbol: "",
    total_supply : "",
    name: "",
    email: "",
    password: "",
  });

  const createTokenschema = Yup.object().shape({
    contract_name: Yup.string().required("Contract name is required"),
    symbol: Yup.string().required("Contract symbol is required"),
    total_supply: Yup.number().required("Contract total is required"),
    name: Yup.string()
      .min(4, "Name must 4 characters at minimum")
      .required("Username is required *"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  const handleSubmit = (values) => {
    props.dispatch(userLoginNftCreateStart(values));
  };

  useEffect(() => {

    if(props.loginNftCreate.code == "1001"){
      setRoute(`create-nft-step2/${props.loginNftCreate.data.nft.nft_unique_id}`)
      handleShow(true);
    }

  }, [props.loginNftCreate.code]);

  return (
    <>
      <div className="container p-0">
        <Formik
          initialValues={{
            contract_name: "",
            symbol: "",
            total_supply : "",
            name: "",
            email: "",
            password: "",
          }}
          className="w-100"
          validationSchema={createTokenschema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form noValidate>
              <div className="container p-0">
                <div className="custom-box-landing m-0 w-100 px-0 py-3 create-token-width custom-shadow">
                  <div className="row no-gutters">
                    <div className="col-lg-12 p-0">
                      {/* <p className="mb-0 container">
                        Using this tool, you can: <br />
                        • Easily create any number of Coins / Tokens /
                        Cryptocurrency in just a few clicks.
                        <br />
                        • Effortlessly create any type of Tokens: Utility Tokens
                        for your ICO, Security Tokens for your STO, Asset Backed
                        Tokens ( ABT ) etc.
                        <br />
                        • Tokenize any of your real world assets ( Real Estate,
                        Art, Agriculture etc. ) in a few clicks.
                        <br />
                        • Create Tokens that has all the regulatory compliance
                        of your Jurisdiction built in.
                        <br />
                        <a
                          target="_blank"
                          href="https://blog.gatecoin.com/why-asset-backed-tokens-will-be-mainstream-56f4aad704c4"
                        >
                          {" "}
                          Learn More.
                        </a>
                      </p>
                      <br />
                      <h5
                        className="text-bold m-0 "
                        style={{ textAlign: "center" }}
                      >
                        IF YOU ARE READY, LETS START WITH THE BASIC DETAILS
                      </h5> */}

                      <div className="custom-box-landing p-0 create-token-width">
                        <div className="createToken-header">
                          <h5 className="text-bold m-0 whitecolor">
                          Contract Details
                          </h5>
                        </div>
                        <div className="form-wrapper p-3">
                          <div className="row">
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                                Contract Name <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="text"
                                  name="contract_name"
                                  placeholder="Contract Name"
                                  className={`input-bordered ${
                                    touched.contract_name && errors.contract_name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="contract_name"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  Choose a name for your contract.
                                </p>
                              </div>
                            </div>
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                                Contract Symbol{" "}
                                <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="text"
                                  name="symbol"
                                  placeholder="Contract Symbol"
                                  className={`input-bordered ${
                                    touched.symbol && errors.symbol
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="symbol"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  ( Ex: BTC, EOS, ETH, XLM etc. )
                                </p>
                              </div>
                            </div>
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                              Contract Total Supply <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="number"
                                  name="total_supply"
                                  placeholder="Contract Total Supply"
                                  className={`input-bordered ${
                                    touched.total_supply &&
                                    errors.total_supply
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="total_supply"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  (Max number of Contracts to be created)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 p-0">
                      <div className="custom-box-landing p-0 create-token-width">
                        <div className="createToken-header">
                          <h5 className="text-bold m-0 whitecolor">
                            User Details
                          </h5>
                        </div>
                        <div className="form-wrapper p-3">
                          <div className="row">
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                                Name <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  className={`input-bordered ${
                                    touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  Enter your name.
                                </p>
                              </div>
                            </div>
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                                Email Address <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Enter your Email-id"
                                  className={`input-bordered ${
                                    touched.email && errors.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  Please,Enter your Email-Id.
                                </p>
                              </div>
                            </div>
                            <div class="form-group col-lg-4">
                              <h5 className="black-text">
                                Password <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder="Enter your password"
                                  className={`input-bordered ${
                                    touched.password && errors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="password"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="mb-0 mt-2 text-muted">
                                  Enter your password.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="d-flex justify-content-center px-3">
                        <button
                          type="submit"
                          className="btn btn-md btn-medium btn-grad no-change p-3"
                          disabled={props.loginNftCreate.buttonDisable}
                        >
                          {props.loginNftCreate.loadingButtonContent !== null
                          ? props.loginNftCreate.loadingButtonContent
                          : "Create NFT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <VerificationCodeModal 
        show={show}
        route={route}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  loginNftCreate: state.users.loginNftCreateInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LandingPageIndex);
