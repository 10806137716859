import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Web3 from "web3";
import LandingCreateTokenTerms from "./LandingCreateTokenTerms";
import { saveTokenStart } from "../../store/actions/TokenAction";
import { connect } from "react-redux";
import TokenHelperModal from "./TokenHelperModal";
import TokenDecimalModal from "./TokenDecimalModal";
import { useLocation } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router";

const LandingCreateTokenPreview = (props) => {
  const { state } = useLocation();

  const [createTokenInput, setCreateTokenInput] = useState();

  const [loading, setLoading] = useState(true);

  const [captchaCheck, setCaptchaCheck] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setCreateTokenInput(state.data);
    setLoading(false);
  }, []);

  const createTokenschema = Yup.object().shape({
    name: Yup.string().required("Token name is required"),
    token_symbol: Yup.string().required("Token symbol is required"),
    decimal_points: Yup.number().required("Token decimal points is required"),
    max_token_supply: Yup.number().required("Token total is required"),
    access_type: Yup.string().required("Access type is required"),
    transfer_type: Yup.string().required("transfer type is required"),
    network_type: Yup.string().required("network type is required"),
    remove_copyrights: Yup.boolean(),
    burnable: Yup.boolean(),
    mintable: Yup.boolean(),
    username: Yup.string()
      .min(4, "username must 4 characters at minimum")
      .required("Username is required *"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
    remove_copyrights: Yup.boolean(),
    burnable: Yup.boolean(),
    mintable: Yup.boolean(),
    token_type: Yup.string().required("Token Type is required"),
    other_token_type: Yup.string().when("token_type", {
      is: (token_type) => token_type == "others",
      then: Yup.string().required("Other Token is required"),
      otherwise: Yup.string(),
    }),
    fund_raise: Yup.number().required("Required *"),
    one_token_price: Yup.number().required("Required *"),
    offering: Yup.array().min(1, "Required *"),
    wallet_address: Yup.string().required("Wallet Address is required *")
  });

  const handleSubmit = async (values) => {
    setCreateTokenInput(values);
    // props.dispatch(
    //   saveTokenStart({
    //     ...values,
    //     description: "Some description static",
    //     initial_token_supply: "10000",
    //   })
    // );
    history.push({
      pathname: "/create-token/creating-token",
      state: { data: values },
    });
  };

  const onCaptchChange = (value) => {
    setCaptchaCheck(value);
  };

  return (
    <>
      <section className="landing-other-pages">
        <div className="container-fluid">
          {loading ? null : (
            <>
              <div class="">
                <Formik
                  initialValues={createTokenInput}
                  className="w-100"
                  validationSchema={createTokenschema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form noValidate>
                      <div className="container p-0">
                        <div className="custom-box-landing m-0 w-100 px-0 py-3 create-token-width custom-shadow">
                          <div className="row no-gutters">
                            <div className="col-lg-12 p-0">
                              <div className="col-12">
                                <p className="mb-0">
                                  <span className="text-danger">*</span> Please check all the details you have
                                  entered below. Edit it if required.
                                </p>
                                <p className="mb-0">
                                  <span className="text-danger">*</span> Scroll down and click the “Create my Tokens”
                                </p>
                              </div>
                              <div className="custom-box-landing p-0 create-token-width">
                                <div className="createToken-header">
                                  <h5 className="text-bold m-0 whitecolor">
                                    Token Details
                                  </h5>
                                </div>
                                <div className="form-wrapper p-3">
                                  <div className="row">
                                    <div class="form-group col-lg-4">
                                      <h5 className="black-text">
                                        Token name{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="name"
                                          placeholder="Token Name"
                                          className={`input-bordered ${touched.name && errors.name
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="name"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Choose a name for your token.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                      <h5 className="black-text">
                                        Your Coin/ Token abbreviation{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="token_symbol"
                                          placeholder="Token Symbol"
                                          className={`input-bordered ${touched.token_symbol &&
                                              errors.token_symbol
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="token_symbol"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          ( Ex: BTC, EOS, ETH, XLM etc. )
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                      <h5 className="black-text">
                                        Token Decimals{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="decimal_points"
                                          placeholder="Token Decimals"
                                          className={`input-bordered ${touched.decimal_points &&
                                              errors.decimal_points
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="decimal_points"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Decimals * (Maximum number of
                                          decimals)?
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#tokenDecimal"
                                          >
                                            What is this?
                                          </a>
                                        </p>
                                      </div>
                                    </div>

                                    <div class="form-group col-lg-4">
                                      <h5 className="black-text">
                                        Total Supply{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="number"
                                          name="max_token_supply"
                                          placeholder="Token Supply"
                                          className={`input-bordered ${touched.max_token_supply &&
                                              errors.max_token_supply
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="max_token_supply"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          (Max number of coins to be created)
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                      <h5 className="black-text">
                                        Wallet Address{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="wallet_address"
                                          placeholder="Wallet Address"
                                          className={`input-bordered ${touched.wallet_address &&
                                            errors.wallet_address
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="wallet_address"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          (Your crypto wallet address)
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 p-0 ">
                              <div className="row no-gutters">
                                <div className="col-lg-4 p-0">
                                  <div className="custom-box-landing p-0 create-token-width ">
                                    <div className="createToken-header">
                                      <h5 className="text-bold m-0 whitecolor">
                                        Token Features
                                      </h5>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div class="form-group">
                                        <h5 className="black-text">
                                          Access Type{" "}
                                          <span class="text-danger">*</span>
                                        </h5>
                                        <div class="controls">
                                          <select
                                            name="access_type"
                                            id="access_type"
                                            className={`input-bordered ${touched.access_type &&
                                                errors.access_type
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                "access_type",
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <option value="none">None</option>
                                            <option value="ownable">
                                              Ownable
                                            </option>
                                            <option value="role_based">
                                              Role Based
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            component="div"
                                            name="access_type"
                                            className="invalid-feedback mt-3"
                                          />
                                          <p className="mb-0 mt-2 text-muted">
                                            None, Ownable, Role Based.
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <h5 className="black-text">
                                          Transfer Type{" "}
                                          <span class="text-danger">*</span>
                                        </h5>
                                        <div class="controls">
                                          <select
                                            name="transfer_type"
                                            id="transfer_type"
                                            className={`input-bordered ${touched.transfer_type &&
                                                errors.transfer_type
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                "transfer_type",
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <option value="Unstoppable">
                                              Unstoppable
                                            </option>
                                            <option value="Pausable">
                                              Pausable
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            component="div"
                                            name="transfer_type"
                                            className="invalid-feedback mt-3"
                                          />
                                          <p className="mb-0 mt-2 text-muted">
                                            Unstoppable, Pausable
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0">
                                  <div className="custom-box-landing p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h5 className="text-bold m-0 whitecolor">
                                        Token Type and Network
                                      </h5>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div class="form-group">
                                        <h5 className="black-text">
                                          Coin should be based on{" "}
                                          <span class="text-danger">*</span>
                                        </h5>
                                        <div class="controls">
                                          <select
                                            name="network_type"
                                            id="network_type"
                                            className={`input-bordered ${touched.network_type &&
                                                errors.network_type
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                "network_type",
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <option value="Ethereum">
                                              Ethereum
                                            </option>
                                            <option value="Binance">
                                              Binance
                                            </option>
                                            <option value="Polygon">
                                              Polygon
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            component="div"
                                            name="network_type"
                                            className="invalid-feedback mt-3"
                                          />
                                          <p className="mb-0 mt-2 text-muted">
                                            Choose your Network.
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <h5 className="black-text">
                                          What are you looking to Tokenize?{" "}
                                          <span class="text-danger">*</span>
                                        </h5>
                                        <div class="controls">
                                          <select
                                            name="token_type"
                                            id="token_type"
                                            className={`input-bordered ${touched.token_type &&
                                                errors.token_type
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                            onChange={(event) => {
                                              setFieldValue(
                                                "token_type",
                                                event.target.value
                                              );
                                              setCreateTokenInput({
                                                ...createTokenInput,
                                                [event.target.name]:
                                                  event.target.value,
                                              });
                                            }}
                                          >
                                            <option value="equity">
                                              Equity Token
                                            </option>
                                            <option value="asset">
                                              Asset Token
                                            </option>
                                            <option value="stable">
                                              Stable Coin
                                            </option>
                                            <option value="utility">
                                              Utility Token
                                            </option>
                                            <option value="others">
                                              others
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            component="div"
                                            name="token_type"
                                            className="invalid-feedback mt-3"
                                          />
                                          <p className="mb-0 mt-2 text-muted">
                                            Choose the type of token you are
                                            trying to create (Utility, Asset,
                                            Equity)
                                            <a href="https://www.bitcoinmarketjournal.com/equity-token/">
                                              {" "}
                                              Learn more
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                      {/* {createTokenInput.token_type == "others" && (
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        Please define your token type{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="other_token_type"
                                          placeholder="Your Token Type"
                                          className={`input-bordered ${
                                            touched.other_token_type &&
                                            errors.other_token_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="other_token_type"
                                          className="invalid-feedback mt-3"
                                        />
                                      </div>
                                    </div>
                                  )} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0">
                                  <div className="custom-box-landing p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h5 className="text-bold m-0 whitecolor">
                                        Extra features
                                      </h5>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div className="form-group">
                                        <div className="controls">
                                          <div className="custom-checkbox mb-3">
                                            <Field
                                              className="switch landing"
                                              type="checkbox"
                                              name="remove_copyrights"
                                            />
                                            <h5 className="ml-3 mb-0 black-text">
                                              Remove Copyright
                                            </h5>
                                          </div>
                                          <p className="mb-0 mt-2 text-muted">
                                            Remove the link pointing to this
                                            page from your contract.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="controls">
                                          <div className="custom-checkbox mb-3">
                                            <Field
                                              className="switch landing"
                                              type="checkbox"
                                              name="burnable"
                                            />
                                            <h5 className="ml-3 mb-0  black-text">
                                              Burnable
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="controls">
                                          <div className="custom-checkbox mb-3">
                                            <Field
                                              className="switch landing"
                                              type="checkbox"
                                              name="mintable"
                                            />
                                            <h5 className="ml-3 mb-0  black-text">
                                              Mintable
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 p-0">
                              <div className="custom-box-landing p-0 create-token-width">
                                <div className="createToken-header">
                                  <h5 className="text-bold m-0 whitecolor">
                                    User Details
                                  </h5>
                                </div>
                                <div className="form-wrapper p-3">
                                  <div className="">
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        Username{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="text"
                                          name="username"
                                          placeholder="Username"
                                          className={`input-bordered ${touched.username && errors.username
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="username"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Enter your username.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        Email Address{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="email"
                                          name="email"
                                          placeholder="Enter your Email-id"
                                          className={`input-bordered ${touched.email && errors.email
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="email"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Please,Enter your Email-Id.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        Password{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="controls">
                                        <Field
                                          type="password"
                                          name="password"
                                          placeholder="Enter your password"
                                          className={`input-bordered ${touched.password && errors.password
                                              ? "is-invalid"
                                              : ""
                                            }`}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="password"
                                          className="invalid-feedback mt-3"
                                        />
                                        <p className="mb-0 mt-2 text-muted">
                                          Enter your password.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 p-0">
                              <div className="custom-box-landing p-0 create-token-width">
                                <div className="createToken-header">
                                  <h5 className="text-bold m-0 whitecolor">
                                    Tokenizer
                                  </h5>
                                </div>
                                <div className="form-wrapper p-3">
                                  <div className="">
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        How much funding are you aiming to
                                        raise?{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="custom-input-group mb-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">
                                            $
                                          </span>
                                        </div>
                                        <div class="controls">
                                          <Field
                                            type="number"
                                            name="fund_raise"
                                            placeholder=""
                                            className={`input-bordered ${touched.fund_raise &&
                                                errors.fund_raise
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                          />
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        component="div"
                                        name="fund_raise"
                                        className="invalid-feedback mt-3"
                                      />
                                    </div>
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        What should be the price of one token?{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div class="custom-input-group mb-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">
                                            $
                                          </span>
                                        </div>
                                        <div class="controls">
                                          <Field
                                            type="number"
                                            name="one_token_price"
                                            placeholder=""
                                            className={`input-bordered ${touched.one_token_price &&
                                                errors.one_token_price
                                                ? "is-invalid"
                                                : ""
                                              }`}
                                          />
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        component="div"
                                        name="one_token_price"
                                        className="invalid-feedback mt-3"
                                      />
                                    </div>
                                    <div class="form-group">
                                      <h5 className="black-text">
                                        In which parts of the world will you be
                                        marketing your offering?{" "}
                                        <span class="text-danger">*</span>
                                      </h5>
                                      <div className="controls">
                                        <div
                                          role="group"
                                          aria-labelledby="checkbox-group"
                                        >
                                          <ul className="d-flex flex-wrap  gutter-30px">
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-1"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Asia"
                                                />
                                                <label for="rdi-1">Asia</label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-2"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="USA"
                                                />
                                                <label for="rdi-2">USA</label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-3"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Canada"
                                                />
                                                <label for="rdi-3">
                                                  Canada
                                                </label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-4"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Europe"
                                                />
                                                <label for="rdi-4">
                                                  Europe
                                                </label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-5"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Australia"
                                                />
                                                <label for="rdi-5">
                                                  Australia
                                                </label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-6"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Latin America"
                                                />
                                                <label for="rdi-6">
                                                  Latin America
                                                </label>
                                              </div>
                                            </li>
                                            <li className="mb-3">
                                              <div className="field-wrap checkbox">
                                                <Field
                                                  id="rdi-7"
                                                  name="offering"
                                                  type="checkbox"
                                                  value="Africa"
                                                />
                                                <label for="rdi-7">
                                                  Africa
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                        <ErrorMessage
                                          component="div"
                                          name="offering"
                                          className="invalid-feedback mt-3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 p-0 ">
                              <div className="row no-gutters">
                                <div className=" p-0">
                                  <div className="custom-box-landing p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h5 className="text-bold m-0 whitecolor">
                                        Agreement
                                      </h5>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div className="checkbox">
                                        <input type="checkbox" id="agreement" />
                                        <label htmlFor="agreement">
                                          I have read, understood and agreed to
                                          ERC20 Token Generator's{" "}
                                          <a
                                            href="#"
                                            className="text-bold black-text"
                                            data-toggle="modal"
                                            data-target="#landingtermscondition"
                                          >
                                            Terms of Use
                                          </a>
                                          .
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 p-0">
                                  <div className="custom-box-landing p-0 create-token-width">
                                    <div className="createToken-header">
                                      <h5 className="text-bold m-0 whitecolor">
                                        Transaction
                                      </h5>
                                    </div>
                                    <div className="form-wrapper p-3">
                                      <div className="spliter">
                                        <div className="row">
                                          <div className="col-12">
                                            <h5 className="black-text text-bold d-flex">
                                              Gas Fee :{" "}
                                              <span className="ml-auto">
                                                0.001523 ETH
                                              </span>
                                            </h5>
                                          </div>
                                          {/* <div className="col-4">
                                        <h5 className="black-text">{gasEstimation} ETH</h5>
                                      </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 p-0">
                                  <div className="d-flex justify-content-center my-3 flex-column p-3">
                                    <div className="mb-3">
                                      <ReCAPTCHA
                                        sitekey="6Ld20W8cAAAAACjG0Akp65fOspk3cDYOfCFp2Zsz"
                                        onChange={onCaptchChange}
                                      />
                                    </div>
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-medium btn-grad no-change p-3"
                                      disabled={
                                        captchaCheck
                                          ? props.saveToken.buttonDisable
                                          : true
                                      }
                                    >
                                      {props.saveToken.loadingButtonContent !==
                                        null
                                        ? props.saveToken.loadingButtonContent
                                        : "Create Token"}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn no-btn p-3"
                                      disabled={props.saveToken.buttonDisable}
                                      data-toggle="modal"
                                      data-target="#tokenHelper"
                                    >
                                      Need Help?
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </section>
      <LandingCreateTokenTerms />
      <TokenHelperModal />
      <TokenDecimalModal />
    </>
  );
};

const mapStateToPros = (state) => ({
  saveToken: state.token.saveToken,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(LandingCreateTokenPreview);
