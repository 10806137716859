import React , {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#171717",
    color: "#fff",
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    fontWeight:'bold',
    alignItems: 'center',
    //border: `2px solid red`,
    zIndex:0,
  },
  active: {
    color: "#fff",
    backgroundColor: "#171717",
     border: `2px solid #fff`,
  },
  completed: {
    color: "#fff",
    backgroundColor: "#171717",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
});

export const  ColorlibStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6"
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
       
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      {completed || icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {

  active: PropTypes.bool,

  completed: PropTypes.bool,

  icon: PropTypes.node,
};

export const useStepperStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: "0 !important",
    "& .MuiStepper-root": {
      overflowX: "auto",
      padding: "1em",
      overflowY: "hidden",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      borderRadius: "1em",
      margin: "0 !important",
    },
    "& .MuiButtonBase-root.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
    "& .MuiStepLabel-root.Mui-disabled": {
      cursor: "not-allowed",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#ab8ce4",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#ab8ce4",
    },
    "& .MuiButtonBase-root:focus": {
      outline: "none",
    },
    "& .MuiButtonBase-root .MuiStepLabel-root" :{
      display: "flex",
      flexDirection : "row",
      gap : "1em"
    },
    "& .MuiButtonBase-root .MuiStepLabel-root .MuiTypography-root" : {
      margin: "0 !important",
      color : "gray"
    },
    "& .MuiButtonBase-root .MuiStepLabel-root .MuiTypography-root.MuiStepLabel-active ": {
      color: "#fff",
    },
    "& .MuiButtonBase-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg ": {
      width: "25px",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));