import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import ConnectWallet from "../../Modals/ConnectWallet";
import { authContext } from "../../auth/authprovider/AuthProvider";
import { CHAIN_INFO, SupportedChainId } from "../../auth/authprovider/chains";
import AccountModal from "../../auth/AccountModal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { styled, alpha } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    top: "50px !important",
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const HeaderIndex = (props) => {
  const [searchBar, setSearchBar] = useState(false);

  const { auth , setAuth } = useContext(authContext);

  const toggleSearchbar = (status) => {
    setSearchBar(status);
  };

  const [accountModalStatus, setAccountModalStatus] = useState(false);

  const handleAccountModalClose = () => setAccountModalStatus(false);
  const handleAccountModalOpen = () => setAccountModalStatus(true);

  useEffect(() => {
    handleAccountModalClose();
  }, [auth.authStatus]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNetworkDropClose = () => {
    setAnchorEl(null);
  };

  const handleNetworkChange = (chain) => {
    handleNetworkDropClose()
    setAuth({
      ...auth,
      chainId : chain
    })
  }

  return (
    <>
      <header className="main-header">
        <Link to="/" className="logo">
          <b className="logo-mini">
            <span className="light-logo">
              <img
                src={configuration.get("site_icon")}
                srcSet={configuration.get("site_icon")}
                alt={configuration.get("site_name")}
              />
            </span>
            <span className="dark-logo">
              <img
                src={configuration.get("site_icon")}
                srcSet={configuration.get("site_icon")}
                alt={configuration.get("site_name")}
              />
            </span>
          </b>
          <span className="logo-lg">
            <img
              src={
                configuration.get("configData.site_logo")
                  ? configuration.get("configData.site_logo")
                  : window.location.origin + "/logo-white.png"
              }
              srcSet={
                configuration.get("configData.site_logo")
                  ? configuration.get("configData.site_logo")
                  : window.location.origin + "/logo-white.png"
              }
              alt={configuration.get("site_name")}
              className="light-logo"
            />
            <img
              src={
                configuration.get("configData.site_logo")
                  ? configuration.get("configData.site_logo")
                  : window.location.origin + "/logo.png"
              }
              srcSet={
                configuration.get("configData.site_logo")
                  ? configuration.get("configData.site_logo")
                  : window.location.origin + "/logo.png"
              }
              alt={configuration.get("site_name")}
              className="dark-logo"
            />
          </span>
        </Link>
        <nav className="navbar navbar-static-top">
          <a
            href="#"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>

          <div className="navbar-custom-menu">
            <Button
              id="network-change-button"
              aria-controls={open ? "network-change-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
            >
              <img src={CHAIN_INFO[auth.chainId].logo} alt="" />
              {CHAIN_INFO[auth.chainId].label}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "network-change-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleNetworkDropClose}
            >
              <div className="network-dropdown-title">
              <h5 className="mb-0 text-muted">Select a network</h5>
              </div>
              {Object.entries(SupportedChainId).map((chain, index) => (
                <>
                  <MenuItem onClick={() => handleNetworkChange(chain[1])} disableRipple key={index}>
                    <div className="chains-item">
                      <img
                        src={
                          CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                            .logo
                        }
                        alt=""
                      />
                      <h5>
                        {
                          CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                            .label
                        }
                      </h5>
                    </div>
                  </MenuItem>
                </>
              ))}
            </StyledMenu>
            {auth.accounts.length > 0 ? (
              <div className="wallet-address-badge">
                <div className="balance-wrapper">
                  <div className="balance">
                    <h6>
                      {Number(auth.ethBalance).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                      })}{" "}
                      {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}{" "}
                    </h6>
                  </div>
                  <div
                    className="account"
                    onClick={() => handleAccountModalOpen()}
                  >
                    <h6>
                      {auth.accounts.substr(0, 5)}...
                      {auth.accounts.substr(auth.accounts.length - 4)}
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              <a
                href="#"
                className="connect-wallet-btn"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#connect-wallet-popup"
              >
                Connect Wallet
              </a>
            )}

            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  {props.profile.loading ? (
                    <img
                      src={
                        window.location.origin +
                        "/assets/images/user5-128x128.jpg"
                      }
                      className="user-image rounded-circle"
                      alt="User Image"
                    />
                  ) : (
                    <img
                      src={props.profile.data.picture}
                      className="user-image rounded-circle"
                      alt="User Image"
                    />
                  )}
                </a>
                <ul className="dropdown-menu scale-up">
                  <li className="user-header d-flex">
                    {props.profile.loading ? (
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/user5-128x128.jpg"
                        }
                        className="float-left rounded-circle"
                        alt="User Image"
                      />
                    ) : (
                      <img
                        src={props.profile.data.picture}
                        className="float-left rounded-circle"
                        alt="User Image"
                      />
                    )}
                  </li>
                  <li className="user-body">
                    <div className="row no-gutters">
                      <div className="col-12 text-left">
                        <Link to="/profile">
                          <i className="ion ion-person"></i> My Profile
                        </Link>
                      </div>

                      <div role="separator" className="divider col-12"></div>
                      <div role="separator" className="divider col-12"></div>
                      <div className="col-12 text-left">
                        <Link to="/logout">
                          <i className="fa fa-power-off"></i> Logout
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="#" data-toggle="control-sidebar">
                  <i className="fa fa-cog fa-spin"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
      <ConnectWallet />
      {auth.authStatus && accountModalStatus && (
        <AccountModal
          status={accountModalStatus}
          handleClose={handleAccountModalClose}
        />
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(HeaderIndex);
