import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchSingleNFTStart } from "../store/actions/NFTImagesActions";
import { Row, Col } from "react-bootstrap";
import {
  editNFTDetails,
  updateNFTDetailsStart,
} from "../store/actions/NFTImagesActions";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

const EditNFT = (props) => {
  const { merge_image_unique_id , nft_unique_id } = useParams();

  const [image, setImage] = useState({
    nft_image: {
      file: "",
      preview_image: "",
    },
  });

  const [nftImagesDatas, setNftImagesData] = useState({
    properties: [],
  });

  useEffect(() => {
    props.dispatch(
      fetchSingleNFTStart({ merge_image_unique_id: merge_image_unique_id })
    );
  }, []);

  const editNftImagesschema = Yup.object().shape({
    properties: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("value is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    const newData = { 
      properties : JSON.stringify(values.properties) ,  
      merge_image_unique_id: merge_image_unique_id , 
      nft_unique_id : nft_unique_id ,
      file_name : values.file_name ,
    };
    if (image.nft_image.file == "") {
      delete newData.picture;
    } else {
      newData.picture = image.nft_image.file;
    }
    props.dispatch(
      updateNFTDetailsStart({ 
        ...newData
      })
    );
  };

  const handleProfileImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        nft_image: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageRemove = () => {
    setImage({
      ...image,
      nft_image: {
        file: "",
        preview_image: "",
      },
    });
  };

  useEffect(() => {
    if (
      !props.singleNFT.loading &&
      props.singleNFT.data.merge_image_properties
    ) {
      setNftImagesData((prev) => ({
        ...prev.nftImagesDatas,
        properties: props.singleNFT.data.merge_image_properties.map(
          (properties, index) => {
            return {
              name: properties.nft_property.name,
              value: properties.value,
              merge_image_id: properties.merge_image_id,
              nft_property_id: properties.nft_property_id,
              merge_image_property_id: properties.merge_image_property_id,
            };
          }
        ),
      }));
    }
  }, [props.singleNFT.data]);

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Nft</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft Edit Image</li>
          </ol>
        </section>
        <div className="custom-box my-4">
          {props.singleNFT.loading ? (
            "Loading..."
          ) : (
            <Formik
              initialValues={{
                properties: nftImagesDatas.properties,
                file_name: props.singleNFT.data.nft_image ? props.singleNFT.data.nft_image.file_name : "",
              }}
              className="w-100"
              validationSchema={editNftImagesschema}
              onSubmit={(values) => handleSubmit(values)}
              enableReinitialize
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  {props.singleNFT.data.nft_image && (
                    <>
                      <Row>
                        <Col md={6}>
                          <Col md={12} >
                            <div className="form-group mb-3">
                              <h5 className="white-color text-bold">
                                File Name{" "}
                                <span className="text-danger">*</span>
                              </h5>
                              <div className="controls">
                                <Field
                                  name="file_name"
                                  type="text"
                                  className={`form-control ${
                                    touched.file_name && errors.file_name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              </div>
                              <ErrorMessage
                                component="div"
                                name="file_name"
                                className="invalid-feedback mt-3"
                              />
                            </div>
                          </Col>
                          <FieldArray name="properties">
                            {() =>
                              values.properties.map((properties, index) => {
                                const propertiesErrors =
                                  (errors.properties?.length &&
                                    errors.properties[index]) ||
                                  {};
                                const propertiesTouched =
                                  (touched.properties?.length &&
                                    touched.properties[index]) ||
                                  {};
                                return (
                                  <Col md={12} key={index}>
                                    <div className="form-group mb-3">
                                      <h5 className="white-color text-bold">
                                        {properties.name}{" "}
                                        <span className="text-danger">*</span>
                                      </h5>
                                      <div className="controls">
                                        <Field
                                          name={`properties.${index}.value`}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (propertiesErrors.value &&
                                            propertiesTouched.value
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name={`properties.${index}.value`}
                                          className="invalid-feedback mt-3"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })
                            }
                          </FieldArray>
                          <Col md={12}>
                            <div className="block text-sm mb-2">
                              <h5 className="white-color text-bold">
                                NFT Image
                              </h5>
                              <div className="custom-file-btn-wrapper ">
                                <input
                                  id="fileSelect"
                                  type="file"
                                  accept="image/*"
                                  name="picture"
                                  onChange={(event) => {
                                    handleProfileImageChange(event);
                                    props.dispatch(
                                      editNFTDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                />
                                <button className="btn btn-primary withTheme">
                                  Choose NFT Image
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Col>
                        <Col md={6}>
                          <Row className="justify-content-md-center">
                            <Col md={8}>
                              {image.nft_image.preview_image != "" ? (
                                <div className="edit-nftimage-preview-image-wrapper">
                                  <img
                                    src={image.nft_image.preview_image}
                                    alt=""
                                    className="settings-previewimage "
                                  />
                                  <div className="file-remove-btn">
                                    <button
                                      className=" btn btn-info button-icon m-10 danger"
                                      aria-label="Delete"
                                      onClick={handleProfileImageRemove}
                                    >
                                      <svg
                                        fill="#fff"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        width="24"
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                props.singleNFT.data.nft_image.picture !=
                                  "" && (
                                  <div>
                                    <img
                                      src={
                                        props.singleNFT.data.nft_image.picture
                                      }
                                      alt=""
                                      className="settings-previewimage "
                                    />
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="w-100 text-center">
                        <button
                          className="btn btn-primary withTheme"
                          type="submit"
                          disabled={props.editNFTInputdata.buttonDisable}
                        >
                          {props.editNFTInputdata.loadingButtonContent !== null
                            ? props.editNFTInputdata.loadingButtonContent
                            : "Submit"}
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nftImages.singleNFT,
  editNFTInputdata: state.nftImages.editNFTInputdata,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditNFT);
