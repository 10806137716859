import React, { useEffect, useState, useContext } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchSingleNftStart,
  nftGeneratePaymentSaveStart,
} from "../store/actions/NftAction";
import { Redirect, useParams } from "react-router";
import Web3 from "web3";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { SupportedChainId, CHAIN_INFO } from "../auth/authprovider/chains";
import { useWeb3React } from "@web3-react/core";
import { authContext } from "../auth/authprovider/AuthProvider";
import ConnectWalletModal from "../auth/ConnectWalletModal";
import configuration from "react-global-configuration";
import NftToken from "../../abis/NftToken.json";
import axios from "axios";

const GenerateNftPayment = (props) => {
  const { nft_unique_id } = useParams();
  const context = useWeb3React();
  const { chainId, account, active } = context;
  const { auth, setAuth, hanldeLogout } = useContext(authContext);

  useEffect(() => {
    props.dispatch(fetchSingleNftStart({ nft_unique_id: nft_unique_id }));
  }, []);

  const etherNetID = 1;

  const binanceNetID = SupportedChainId.BINANCE; // mainnet - 56 // test = 97

  const polygonNetID = SupportedChainId.POLYGON; // mainnet - 137 // test = 80001

  const velasNetID = SupportedChainId.VELAS;

  const [loading, setLoading] = useState(true);

  //const [account, setAccount] = useState("");

  const [ethBalance, setEthBalance] = useState("0");

  const [crytoSymbol, setCrytoSymbol] = useState("ETH");

  const [gasEstimation, setGasEstimation] = useState("0");

  const [metamaskNetStatus, setMetamaskNetStatus] = useState("ether");

  const [deployContractButtonContent, setDeployContractButtonContent] =
    useState("");

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [priceUSD, setPriceUsD] = useState("0");
  const [payMeButtonContent, setPayMeButtonContent] = useState("");

  useEffect(() => {
    if (deployContractButtonContent !== "") {
      window.onbeforeunload = function () {
        console.log("refreshed..!!");
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [deployContractButtonContent]);

  const findNet = async () => {
    switch (chainId) {
      case etherNetID:
        setMetamaskNetStatus("ether");
        return "ETH";
        break;
      case binanceNetID:
        setMetamaskNetStatus("bnb");
        return "BNB";
        break;
      case polygonNetID:
        setMetamaskNetStatus("polygon");
        return "MATIC";
        break;
      default:
        setMetamaskNetStatus("");
        return "";
        break;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    //setAccount(accounts[0]);

    const ethBalance = await web3.eth.getBalance(accounts[0]);
    setEthBalance(ethBalance);

    findNet().then((val) => setCrytoSymbol(val));
    setLoading(false);

    estimateGasPrice();
  };

  const estimateGasPrice = () => {
    const web3 = window.web3;
    web3.eth.getGasPrice().then((result) => {
      setGasEstimation(web3.utils.fromWei(result, "ether"));
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let web3 = window.web3;

      let response;
      setPayMeButtonContent("Initied. Please Wait...");

      let account = auth.accounts;

      if (account != "") {
        // Send ether to admin account.

        let paymentAmount =
          priceUSD *
          Number(
            configuration.get("configData.nft_generator_upload_amount")
              ? configuration.get("configData.nft_generator_upload_amount")
              : 0.01
          );
        paymentAmount = paymentAmount.toString();
        if (metamaskNetStatus == "ether" && chainId == etherNetID) {
          console.log("ether payment");
          response = await web3.currentProvider.eth.sendTransaction({
            from: account,
            to: "0x57df1D419DF8fF508e2979bA551977487a064608",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
          });
        } else if (metamaskNetStatus == "bnb" && chainId == binanceNetID) {
          response = await web3.eth.sendTransaction({
            from: account,
            to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
          });
        } else if (metamaskNetStatus == "polygon" && chainId == polygonNetID) {
          response = await web3.eth.sendTransaction({
            from: account,
            to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
          });
          console.log("response", response);
        } else {
          window.alert(
            "Please change the network into " +
              metamaskNetStatus +
              ". Once you changed the network in metamask refresh the page."
          );
          return;
        }
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Please choose the network type..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
        return;
      }
      if (response !== null) {
        // Save the transaction details.
        console.log("Payment received..");
        props.dispatch(
            nftGeneratePaymentSaveStart({
              nft_unique_id: props.singleNft.data.nft.nft_unique_id,
              upload_type : 1,
            })
          );
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Payment Failed. Please try again..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
      }
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Payment Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      console.log("Payment is not done..", error);
      setPayMeButtonContent("");
    }
  };

  useEffect(() => {
    if (chainId && account) {
      findNet().then((val) => setCrytoSymbol(val));
    }
  }, [chainId]);

  const handleConnectWalletClose = () => setConnectWalletModalStatus(false);
  const handleConnectWalletOpen = () => setConnectWalletModalStatus(true);

  useEffect(() => {
    if (account) {
      handleConnectWalletClose();
    }
  }, [account]);

  const getPrice = async () => {
    let networkName;

    switch (auth.chainId) {
      case etherNetID:
        networkName = "ether";
        break;
      case binanceNetID:
        networkName = "binancecoin";
        break;
      case polygonNetID:
        networkName = "matic-network";
        break;
      case velasNetID:
        networkName = "velas";
        break;
      default:
        networkName = null;
        break;
    }

    const priceData = await axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${networkName}&vs_currencies=usd`,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data[networkName].usd;
        } else {
          return null;
        }
      });
    setPriceUsD(priceData);
  };

  useEffect(() => {
    getPrice();
  }, [auth.chainId]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <>
      <div
        className="content-wrapper min-heigth-100vh transaction-table"
        id="token-confirmation"
      >
        <section className="content-header">
          <h1>Generate NFT Payment</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Generate NFT Payment</li>
          </ol>
        </section>
        {
          props.singleNft.loading ? (
            "Loading...."
          ) : (
            <>
              <section className="">
                <div className="container-fluid text-center">
                  <div className="custom-box p-3 p-lg-4 custom-shadow m-0 col-lg-9 m-5">
                    <h3 className="text-bold m-0 whitecolor text-left">
                      Generate/Upload Payment 
                    </h3>
                    <div class="callout mt-3 text-left">
                      <h4>Notes:</h4>
                        <ul>
                          <li>Connect your wallet (Metamask or WalletConnect) to make payment to generate or upload NFT in the respective blockchain you have selected for the first payment.</li>
                        </ul>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-6">
                        <div className="deploy-image-wrapper">
                          <img
                            src={
                              window.location.origin +
                              "/assets/images/deploy_contract.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 align-items-center d-flex justify-content-center mt-3 mt-md-0">
                        <div className="admin-amount-wrapper">
                          <div className="tokens-info-wrapper">
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Token Name <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {props.singleNft.data.nft.contract_name}
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Total Supply <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {props.singleNft.data.nft.total_supply}
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Token Symbol <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {props.singleNft.data.nft.symbol}
                              </h5>
                            </div>
                          </div>
                          <div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Gas Fee <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0"> {gasEstimation}</h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Balance <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {Number(auth.ethBalance).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}{" "}
                                {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Pay amount <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {(
                                  Number(priceUSD) *
                                  Number(
                                    configuration.get(
                                      "configData.nft_generator_upload_amount"
                                    )
                                      ? configuration.get(
                                          "configData.nft_generator_upload_amount"
                                        )
                                      : 0.01
                                  )
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 3,
                                })}{" "}
                                {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}
                              </h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Network <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {CHAIN_INFO[auth.chainId].label}
                              </h5>
                            </div>
                          </div>
                        

                          <div className="mt-2 text-center">
                            {active === false && chainId == undefined ? (
                              <div className="buttons-wrapper">
                                <button
                                  type="submit"
                                  className="btn btn-primary withTheme web3-button-connect"
                                  onClick={() => handleConnectWalletOpen()}
                                >
                                  Connect Wallet
                                </button>
                              </div>
                            ) : (
                              <div className="buttons-wrapper">
                                <button
                                  type="submit"
                                  className="btn btn-primary withTheme"
                                  onClick={handleSubmit}
                                  disabled={
                                    payMeButtonContent !== ""
                                      ? true
                                      : deployContractButtonContent !== ""
                                      ? true
                                      : false
                                  }
                                >
                                  {payMeButtonContent !== ""
                                    ? payMeButtonContent
                                    : deployContractButtonContent !== ""
                                    ? deployContractButtonContent
                                    : "Pay for generator"}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger withTheme web3-button-connect"
                                  onClick={hanldeLogout}
                                >
                                  Logout
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
        }
      </div>
      {connectWalletModalStatus && (
        <ConnectWalletModal
          status={connectWalletModalStatus}
          handleConnectWalletClose={handleConnectWalletClose}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNft: state.nft.singleNft,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(GenerateNftPayment);
