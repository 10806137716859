import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SupportedChainId, CHAIN_INFO } from "../auth/authprovider/chains";
import { useWeb3React } from "@web3-react/core";
import { authContext } from "../auth/authprovider/AuthProvider";
import ConnectWalletModal from "../auth/ConnectWalletModal";

const NFTImagesPayamentModal = (props) => {
  const context = useWeb3React();
  const { chainId, account, active } = context;
  const { auth, setAuth, hanldeLogout } = useContext(authContext);

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const checkMetaMaskNetwork = async (value) => {
    setAuth({
      ...auth,
      chainId: Number(value),
    });
  };

  const handleConnectWalletClose = () => setConnectWalletModalStatus(false);
  const handleConnectWalletOpen = () => setConnectWalletModalStatus(true);

  useEffect(() => {
    if (account) {
      handleConnectWalletClose();
    }
  }, [account]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="custom-modal"
      >
        <Modal.Header>
          <div className="custom-header">
            <Modal.Title className="white-color mb-0">
              {props.headerTitle}
            </Modal.Title>
            <FontAwesomeIcon icon={faTimes} onClick={props.handleClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {props.singleNft.loading ? (
            "Loading...."
          ) : (
            <>
              <section className="">
                <div className="">
                  <div className="custom-shadow m-0">
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="tokens-info-wrapper pr-md-4">
                          <div className="info-wrap row">
                            <h5 className="text-bold m-0 whitecolor col-6 text-left">
                              Token Name <span className="mx-2">:</span>
                            </h5>
                            <h5 className="m-0 crop-text col-6" title={props.singleNft.data.nft.contract_name}>
                              {props.singleNft.data.nft.contract_name}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Total Supply <span className="mx-2">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.singleNft.data.nft.total_supply}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Token Symbol <span className="mx-2">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.singleNft.data.nft.symbol}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <p>Note: The request will be send to admin mail and the merged images will be uploaded by the admin shortly.</p>
                          </div>
                          {/* <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Gas Fee <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0"> 0.003</h5>
                          </div> */}
                          <div className="buttons-wrapper mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary withTheme"
                              onClick={props.generateFunction}
                              disabled={
                                props.genrateAction.buttonDisable
                              }
                            >
                              {props.genrateAction.loadingButtonContent ==
                                null
                                ? "Send request"
                                : props.genrateAction.loadingButtonContent}
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mt-3 align-items-center d-flex justify-content-center mt-3">
                        <div className="admin-amount-wrapper payment-modal w-100">
                          <div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Gas Fee <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0"> 0.005</h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Balance <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {" "}
                                {Number(auth.ethBalance).toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 3 }
                                )}{" "}
                                {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}{" "}
                              </h5>
                            </div>
                          </div>
                          <RadioGroup
                            aria-label="coin"
                            name="coin"
                            row
                            value={auth.chainId}
                            onChange={(event) =>
                              checkMetaMaskNetwork(event.target.value)
                            }
                          >
                            {Object.entries(SupportedChainId).map(
                              (chain, index) => (
                                <>
                                  <FormControlLabel
                                    value={
                                      Object.entries(SupportedChainId)[index][1]
                                    }
                                    control={<Radio color="primary" />}
                                    label={
                                      CHAIN_INFO[
                                        Object.entries(SupportedChainId)[
                                          index
                                        ][1]
                                      ].nativeCurrency.symbol
                                    }
                                    key={index}
                                  />
                                </>
                              )
                            )}
                          </RadioGroup>
                          <div className="mt-2 text-center">
                            {active === false && chainId == undefined ? (
                              <div className="buttons-wrapper">
                                <button
                                  type="submit"
                                  className="btn btn-primary withTheme web3-button-connect"
                                  onClick={() => handleConnectWalletOpen()}
                                >
                                  Connect Wallet
                                </button>
                              </div>
                            ) : (
                              <div className="buttons-wrapper">
                                <button
                                  type="submit"
                                  className="btn btn-primary withTheme"
                                  onClick={props.generateFunction}
                                  disabled={
                                    props.generateFunction.buttonDisable
                                  }
                                >
                                  {props.genrateAction.loadingButttonContent ==
                                  null
                                    ? "Generate Images"
                                    : props.genrateAction.loadingButttonContent}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger withTheme web3-button-connect"
                                  onClick={hanldeLogout}
                                >
                                  Logout
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </Modal.Body>
      </Modal>
      {connectWalletModalStatus && (
        <ConnectWalletModal
          status={connectWalletModalStatus}
          handleConnectWalletClose={handleConnectWalletClose}
        />
      )}
    </>
  );
};

export default NFTImagesPayamentModal;
