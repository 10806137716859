import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const Authheader = (props) => {
  return (
    <>
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink not-landing"
        id="header"
      >
        <div className="header-main">
          <div className="header-container container">
            <div className="header-wrap">
              <div className="header-logo logo" data-aos="fade-down">
                <a href="/" className="logo-link">
                  <img
                    className="logo-dark"
                    src={
                      configuration.get("configData.site_logo")
                        ? configuration.get("configData.site_logo")
                        : window.location.origin + "/logo.png"
                    }
                    srcSet={
                      configuration.get("configData.site_logo")
                        ? configuration.get("configData.site_logo")
                        : window.location.origin + "/logo.png"
                    }
                    alt={configuration.get("site_name")}
                  />
                  <img
                    className="logo-light"
                    src={
                      configuration.get("configData.site_logo")
                        ? configuration.get("configData.site_logo")
                        : window.location.origin + "/logo-white.png"
                    }
                    srcSet={
                      configuration.get("configData.site_logo")
                        ? configuration.get("configData.site_logo")
                        : window.location.origin + "/logo-white.png"
                    }
                    alt={configuration.get("site_name")}
                  />
                </a>
              </div>
              <div className="header-nav-toggle">
                <a
                  href="#"
                  className="navbar-toggle"
                  data-menu-toggle="header-menu"
                >
                  <div className="toggle-line">
                    <span></span>
                  </div>
                </a>
              </div>

              <div className="header-navbar " data-aos="fade-down">
                <nav className="header-menu" id="header-menu">
                  <ul className="menu">
                    <li>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#register-popup"
                        className="btn btn-md btn-auto btn-grad no-change"
                      >
                        <span>Register</span>
                      </a>
                    </li>
                  </ul>
                  <ul className="menu-btns">
                    <li>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#login-popup"
                        className="btn btn-md btn-auto btn-grad no-change"
                      >
                        <span>Login</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Authheader;
