import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import {
  fetchSingleNFTStart,
  deleteSingleNFTStart,
  updateNFTMintStatusStart,
} from "../store/actions/NFTImagesActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Web3 from "web3";
import MintNowModal from "./MintNowModal";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../auth/authprovider/AuthProvider";

const $ = window.$;

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const SingleNFT = (props) => {
  const { merge_image_unique_id } = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleNFTStart({ merge_image_unique_id: merge_image_unique_id })
    );
  }, []);

  const handleDeleteNFT = () => {
    props.dispatch(
      deleteSingleNFTStart({ merge_image_unique_id: merge_image_unique_id })
    );
  };

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const [pageCount, setPageCount] = useState(0);

  const [deleteNFT, setDeleteNFT] = useState(null);

  const [skipInitialRender, setSkipInitialRender] = useState(false);

  const history = useHistory();

  const [nftLoot, setNftLoot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenURIs, setTokenURLs] = useState([]);
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [minterWalletAddress, setMinterWalletAdddress] = useState("");
  const [mintButtonContent, setMintButtonContent] = useState("");
  const [inputData, setInputData] = useState({
    title: "",
    description: "",
  });
  const [images, setImages] = useState([]);
  const [img, setImg] = useState("");
  const [nftWords, setNftWords] = useState([]);
  const [mintNowModal, setMintNowModal] = useState(false);

  const [mintNowinputData, setMintNowInputData] = useState({
    wallet_address: "",
  });

  const [searchInputData, setSearchInputData] = useState({
    searchKey: "",
    searchStatus: false,
  });

  const { auth } = useContext(authContext);

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  // Generate metadata json file.
  const generateJson = async (data, allWords) => {
    let attributes = [];

    data.properties.map((property) =>
      attributes.push({ trait_type: property.name, value: (property.values ? property.values.value : "") })
    );
    console.log("attribute_values", attributes);
    const metadata = JSON.stringify({
      description: data.description,
      external_url: data.external_url,
      image: data.picture,
      name: data.name,
      text: allWords,
      attributes,
    });
    console.log("Json", metadata);
    return metadata;
  };

  const mintYourNFT = async (data) => {
    setMintButtonContent("Initiated...");

    try {
      let currentData = data;
      setMintButtonContent((prevState) => "Connecting to Blockchain");

      let allWords = "";
      currentData.properties.map((property) => {
        allWords = allWords + " " + (property.values ? property.values.value : "");
      });

      console.log("all words", allWords);
      const json = generateJson(currentData, allWords)
        .then(async (val) => {
          try {
            const cid = await ipfsJson.add(val);
            console.log("cid", cid);
            console.log("minter address", data.nft.wallet_address);

            const web3 = window.web3;
            let nftContractData;
            const NFTData = NftToken.networks[netID];
            if (NFTData) {
              nftContractData = await new web3.eth.Contract(
                NftToken.abi,
                data.nft.wallet_address
              );
            } else {
              window.alert("NFT contract not deployed to detected network.");
            }

            nftContractData.methods
              .mint(
                data.nft.wallet_address,
                "https://ipfs.infura.io/ipfs/" + cid,
                allWords
              )
              .send({ from: auth.accounts })
              .on("error", (error) => {
                let notificationMessage;
                if (error.message == undefined) {
                  notificationMessage = getErrorNotificationMessage(
                    "Same Wallet can't have more than 2 NFT! Use different wallet address"
                  );
                } else {
                  notificationMessage = getErrorNotificationMessage(
                    error.message
                  );
                }
                props.dispatch(createNotification(notificationMessage));
                setMintButtonContent("");
              })
              .once("receipt", (receipt) => {
                setLoading(false);
                setMintButtonContent("");
                props.dispatch(
                  updateNFTMintStatusStart({
                    merge_image_unique_id: data.merge_image_unique_id,
                    mint_status: 3,
                  })
                );
              });
          } catch (error) {
            console.log("Error", error);
            const notificationMessage = getErrorNotificationMessage(
              "Invalid wallet address"
            );
            props.dispatch(createNotification(notificationMessage));
            setMintButtonContent("");
          }

        });

    } catch (error) {
      console.log("error catch", error);
      setMintButtonContent("");
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }

  };

  const handleMintNowModalClose = () => {
    setMintNowModal(false);

    setMinterWalletAdddress("");
  };

  const handleMintModalShow = () => {
    if (connectMetaMask) {
      setMintNowModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Connect your Wallet, Before mint"
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const showLogin = async () => {
    $("#connect-wallet-popup").modal("show")
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Nft</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft Images List</li>
          </ol>
        </section>
        <div className="custom-box my-4">
          {/* <div className="text-right">
            <button
              onClick={connectingMetaMask}
              class=" btn btn-primary withTheme"
            >
              {connectMetaMask ? account : "Connect Wallet"}
            </button>
          </div> */}
          <div className="single-nft-image-view mt-3">
            {props.singleNFT.loading
              ? "Loading"
              : props.singleNFT.data.nft_image && (
                <>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-nft-card">
                        <h4>Contract Name:</h4>
                        <p>{props.singleNFT.data.nft.contract_name}</p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Name:</h4>
                        <p>{props.singleNFT.data.nft_image.file_name}</p>
                      </div>
                      <div className="single-nft-card">
                        <h4>NFT ID:</h4>
                        <p>
                          {
                            props.singleNFT.data.nft_image
                              .merge_image_unique_id
                          }
                        </p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Status:</h4>
                        <div>
                          {props.singleNFT.data.nft_image.mint_status == 1 ? (
                            <p
                              className={`open-status ${props.singleNFT.data.nft_image.mint_status_formatted.btn_class}`}
                            >
                              {
                                props.singleNFT.data.nft_image
                                  .mint_status_formatted.status
                              }
                            </p>
                          ) : (
                            <p
                              className={`inactive-status`}
                            >
                              {
                                props.singleNFT.data.nft_image
                                  .mint_status_formatted.status
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="single-nft-card">
                        <h4>Active Status:</h4>
                        <div>
                          {props.singleNFT.data.nft_image.status == 1 ? (
                            <>
                              <p className="active-status">Approved</p>
                            </>
                          ) : (
                            <>
                              <p className="inactive-status">Declined</p>
                            </>
                          )}
                        </div>
                      </div>
                      {props.singleNFT.data.merge_image_properties.length > 0 && (
                        <>
                          <div className="single-nft-card">
                            <h4 className="dotted-underline">Properties</h4>
                          </div>
                          {props.singleNFT.data.merge_image_properties.map(
                            (properties, index) => (
                              <>
                                <div
                                  key={index}
                                  className="properties-wrapper"
                                >
                                  <div className="single-nft-card">
                                    <h4>{properties.nft_property.name !== "" ? properties.nft_property.name : "N/L"} :</h4>
                                    <p>{properties.value}</p>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </>
                      )}
                      <div className="single-nft-card">
                        <h4>Action:</h4>
                        <div className="buttons-wrapper">
                          {props.singleNFT.data.nft_image.mint_status == 1 && (
                            <>
                              <button
                                onClick={!auth.authStatus ? (values) => showLogin() : (event) => {
                                  mintYourNFT(props.singleNFT.data.nft_image);
                                }}
                                className="btn btn-primary withTheme"
                              >
                                <span>
                                  {mintButtonContent != ""
                                    ? mintButtonContent
                                    : "Mint Now"}
                                </span>
                              </button>
                              <Link
                                //to={`/edit-nft/${merge_image_unique_id}`}
                                to={`/contract/${props.singleNFT.data.nft.nft_unique_id}/edit-nft-image/${props.singleNFT.data.nft_image.merge_image_unique_id}`}
                                className="btn btn-primary withTheme"
                              >
                                Edit
                              </Link>
                            </>
                          )}
                          <button
                            onClick={() => handleDeleteNFT()}
                            disabled={props.deleteSingleNFT.buttonDisabled}
                            className="btn btn-primary withTheme"
                          >
                            <span>
                              {props.deleteSingleNFT.loadingButttonContent !=
                                null
                                ? props.deleteSingleNFT.loadingButttonContent
                                : "Delete"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-nft-img-sec ">
                        <img
                          className="single-nft-img"
                          src={props.singleNFT.data.nft_image.picture}
                          alt="Office"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>

      <MintNowModal
        show={mintNowModal}
        handleClose={handleMintNowModalClose}
        minterWalletAddress={minterWalletAddress}
        setMinterWalletAdddress={setMinterWalletAdddress}
        mintYourNFT={mintYourNFT}
        mintButtonContent={mintButtonContent}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nftImages.singleNFT,
  deleteSingleNFT: state.nftImages.deleteSingleNFT,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleNFT);
