import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_LIST_OF_NFT_START,
  FETCH_SINGLE_NFT_IMAGE_START,
  FETCH_SINGLE_NFT_PROPERTIES_START,
  FETCH_LIST_NFT_PROPERTIES_START,
  DELETE_SINGLE_NFT_START,
  // UPLOAD_NFT_FILES_START,
  SEARCH_NFT_IMAGE_START,
  PAGINATE_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_START,
  UPDATE_NFT_DETAILS_START,
  ADD_NFT_IMAGE_START,
  UPDATE_NFT_MINT_STATUS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";

import {
  fetchListofNFTSuccess,
  fetchListofNFTFailure,
  fetchSingleNFTSuccess,
  fetchSingleNFTFailure,
  fetchSingleNFTPropertiesSuccess,
  fetchSingleNFTPropertiesFailure,
  fetchListNFTPropertiesSuccess,
  fetchListNFTPropertiesFailure,
  deleteSingleNFTSuccess,
  deleteSingleNFTFailure,
  // uploadNFTFilesSuccess,
  // uploadNFTFilesFailure,
  searchNFTImageSuccess,
  searchNFTImageFailure,
  sortListOfNFTSuccess,
  sortListOfNFTFailure,
  updateNFTDetailsSuccess,
  updateNFTDetailsFailure,
  addNFTImageSuccess,
  addNFTImageFailure,
  fetchNftImagesListAfterDelete,
  fetchSingleNFTStart,
  updateNFTMintStatusSuccess,
  updateNFTMintStatusFailure,
} from "../actions/NFTImagesActions";

function* fetchListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_images_list" , action.data
    );
    if (response.data.success) {
      console.log(response.data)
      yield put(fetchListofNFTSuccess(response.data.data));
    } else {
      yield put(fetchListofNFTFailure(response.error));
      const notificationMessage = getErrorNotificationMessage(
        response.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchListofNFTFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_image_view", action.data
    );
    if (response.data.success) {
      yield put(fetchSingleNFTSuccess(response.data.data));
    } else {
      yield put(fetchSingleNFTFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleNFTFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleNFTPropertiesAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_properties_view", action.data
    );
    if (response.data.success) {
      yield put(fetchSingleNFTPropertiesSuccess(response.data.data));
    } else {
      yield put(fetchSingleNFTPropertiesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleNFTPropertiesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchListOfNFTPropertiesAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_properties_index", action.data
    );
    if (response.data.success) {
      yield put(fetchListNFTPropertiesSuccess(response.data.data));
    } else {
      yield put(fetchListNFTPropertiesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchListNFTPropertiesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteSingleNFTStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_image_delete", action.data
    );
    if (response.data.success) {
      yield put(deleteSingleNFTSuccess(response.data.data));
      // window.location.assign("/list_of_nft")
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
        console.log(action)
      yield put(fetchNftImagesListAfterDelete(response.data.data))
    } else {
      yield put(deleteSingleNFTFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteSingleNFTFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

// function* UploadNFTFilesAPI(action) {
//   try {
//     const response = yield api.postMethod(
//       "nft_import", action.data
//     );
//     if (response.data.success) {
//       yield put(uploadNFTFilesSuccess(response.data.data));
//       const notificationMessage = getSuccessNotificationMessage(
//         response.data.message
//       );
//       yield put(createNotification(notificationMessage));
//       window.location.reload()
//     } else {
//       yield put(uploadNFTFilesFailure(response.data.error));
//       const notificationMessage = getErrorNotificationMessage(
//         response.data.error
//       );
//       yield put(createNotification(notificationMessage));
//     }
//   } catch (error) {
//     yield put(uploadNFTFilesFailure(error));
//     const notificationMessage = getErrorNotificationMessage(error.message);
//     yield put(createNotification(notificationMessage));
//   }
// }

function* searchNFTImageAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_image_search", action.data
    );
    if (response.data.success) {
      console.log(response.data.data)
      yield put(searchNFTImageSuccess(response.data.data));
    } else {
      yield put(searchNFTImageFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(searchNFTImageFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* paginateListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_images_list" , action.data
    );
    if (response.data.success) {
      yield put(fetchListofNFTSuccess(response.data.data));
    } else {
      yield put(fetchListofNFTFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchListofNFTFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sortListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_images_list" , action.data
    );
    if (response.data.success) {
      yield put(sortListOfNFTSuccess(response.data.data));
    } else {
      yield put(sortListOfNFTFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sortListOfNFTFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateNFTDetailsAPI(action) {
  try {
    const response = yield api.postMethod("nft_single_merge_images_save", action.data);
    if (response.data.success) {
      yield put(updateNFTDetailsSuccess(response.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      //window.location.reload("");
        yield put(fetchSingleNFTStart({merge_image_unique_id : action.data.merge_image_unique_id}))
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(updateNFTDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateNFTDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addNFTImageAPI(action) {
  try {
    const response = yield api.postMethod("nft_single_merge_images_save", action.data);
    if (response.data.success) {
      yield put(addNFTImageSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(`${window.location.origin}/contract/${action.data.nft_unique_id}/nft-images-list`);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(addNFTImageFailure(response.data.error));
    }
  } catch (error) {
    yield put(addNFTImageFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* updateNFTMintStatusAPI(action) {
  try {
    const response = yield api.postMethod("nft_mint_status_update", action.data);
    if (response.data.success) {
      yield put(updateNFTMintStatusSuccess(response.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      //window.location.reload("");
        yield put(fetchSingleNFTStart({merge_image_unique_id : action.data.merge_image_unique_id}))
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(updateNFTMintStatusFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateNFTMintStatusFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_LIST_OF_NFT_START, fetchListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_NFT_IMAGE_START, fetchSingleNFTAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_NFT_PROPERTIES_START, fetchSingleNFTPropertiesAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_LIST_NFT_PROPERTIES_START, fetchListOfNFTPropertiesAPI),
  ]);
  yield all([
    yield takeLatest(DELETE_SINGLE_NFT_START, deleteSingleNFTStartAPI),
  ]);
  // yield all([
  //   yield takeLatest(UPLOAD_NFT_FILES_START, UploadNFTFilesAPI),
  // ]);
  yield all([
    yield takeLatest(SEARCH_NFT_IMAGE_START, searchNFTImageAPI),
  ]);
  yield all([
    yield takeLatest(PAGINATE_LIST_OF_NFT_START, paginateListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(SORT_LIST_OF_NFT_START, sortListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(UPDATE_NFT_DETAILS_START, updateNFTDetailsAPI),
  ]);
  yield all([
    yield takeLatest(ADD_NFT_IMAGE_START, addNFTImageAPI),
  ]);
  yield all([
    yield takeLatest(UPDATE_NFT_MINT_STATUS_START, updateNFTMintStatusAPI),
  ]);
}
