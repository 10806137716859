import React, { useState, useRef , useEffect } from "react";
import {
  Nav,
  Container,
  Navbar,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uploadNFTFilesStart, 
  exportSampleNftStart, 
  fetchNftPropertiesListStart, 
  fetchSingleNftStart
} from "../store/actions/NftAction";
import NFTImagesPayamentModal from "./NFTImagesPayamentModal";

const NFTUploadFiles = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState({
    xlSheet: null,
    zipFile: null,
  });

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleUploadXLSheet = (event) => {
    setUploadedFiles({
      ...uploadedFiles,
      xlSheet: {
        name: event.target.files[0].name,
        file: event.target.files[0],
      },
    });
  };

  const handleUploadZipFile = (event) => {
    setUploadedFiles({
      ...uploadedFiles,
      zipFile: {
        name: event.target.files[0].name,
        file: event.target.files[0],
      },
    });
  };

  const handleFileUpload = () => {
    if (uploadedFiles.xlSheet != null && uploadedFiles.zipFile != null) {
      const newData = {
        import_excel_file: uploadedFiles.xlSheet.file,
        import_zip_file: uploadedFiles.zipFile.file,
        nft_unique_id : props.match.params.nft_unique_id
      };
      props.dispatch(uploadNFTFilesStart(newData));
    }
  };

  useEffect(() => {
    props.dispatch(
      exportSampleNftStart({
        nft_unique_id: props.match.params.nft_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    props.dispatch(
      fetchNftPropertiesListStart({
        nft_unique_id: props.match.params.nft_unique_id,
      })
    );
  }, []);

  const handlePaymentModalClose = () => setShowPaymentModal(false);
  const handlePaymentShow = () => setShowPaymentModal(true);

  useEffect(() => {
    props.dispatch(
      fetchSingleNftStart({ nft_unique_id: props.match.params.nft_unique_id })
    );
  }, []);

  return (
    <>
      <Container>
        <Col lg="8" className="text-center mx-auto px-0">
          <div className="p-3 mb-8 mt-4">
            <Row className="align-items-center">
              <Col xs={12}>
                <h2 class="mb-3 white-color text-bold text-center">
                  Upload Files for NFT Images
                </h2>
                {/* <h6 className="align-items-center white-color">
                  NOTE: Please download the sample CSV and zip. You need to
                  upload CSV and ZIP file as in the reference. Very Important -
                  zip folder name should be <b>nft_images</b>
                </h6>
                <br /> */}
                <div class="callout mt-3 text-left">
                  <h4>Notes:</h4>
                    <ul>
                      <li>A sample zip and excel file will be generated with the exact NFT properties entered in the previous section.</li>
                      <li>Make sure that the folder inside zip file is renamed as <b>“nft-images”</b></li>
                      <li>Also the number of NFT images uploaded should match the number generated in the sample excel and zip folder.</li>
                    </ul>
                </div>
                <div className="nft-upload-files-form mb-3">
                  <a
                    href={
                     props.sampleNftExcel.data.file_url
                    }
                    download
                    className="btn btn-primary withTheme "
                    disabled={props.sampleNftExcel.buttonDisabled}
                  >
                    Download Sample CSV
                  </a>
                  <a
                    href={
                      window.location.origin + "/assets/sample/nft_images.zip"
                    }
                    download
                    className="btn btn-primary withTheme "
                  >
                    Download Sample Zip
                  </a>
                </div>
              </Col>
              <Col xs={12}>
                <div className=" text-center">
                  <Form className="nft-upload-files-form">
                    <Form.Group className="upload-files-center">
                      {uploadedFiles.xlSheet != null && (
                        <h6 className="mb-3 white-color text-center">
                          {uploadedFiles.xlSheet.name}
                        </h6>
                      )}
                      <div className="custom-file-btn-wrapper">
                        <input
                          id="fileSelect"
                          type="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={(event) => handleUploadXLSheet(event)}
                        />
                        <button className="btn btn-primary withTheme ">
                          Select CSV File
                        </button>
                      </div>
                    </Form.Group>
                    <Form.Group className="upload-files-center">
                      {uploadedFiles.zipFile != null && (
                        <h6 className="mb-3  white-color text-center">
                          {uploadedFiles.zipFile.name}
                        </h6>
                      )}
                      <div className="custom-file-btn-wrapper">
                        <input
                          id="fileSelect"
                          type="file"
                          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                          onChange={(event) => handleUploadZipFile(event)}
                        />
                        <button className="btn btn-primary withTheme ">
                          Select Zip File
                        </button>
                      </div>
                    </Form.Group>
                  </Form>
                  <div className="w-100 mt-3">
                    <div className="buttons-wrapper">
                      <button
                        type="button"
                        className="btn btn-primary withTheme upload-nft-back"
                        onClick={props.handleBack}
                      >
                        Back
                      </button>
                      <Button
                        type="button"
                        size="sm"
                        onClick={handleFileUpload}
                        // onClick={handlePaymentShow}
                        className="btn btn-primary withTheme upload-nft-files-btn px-4 py-2"
                        disabled={
                          uploadedFiles.xlSheet == null
                            ? true
                            : uploadedFiles.zipFile == null
                            ? true
                            : false || props.uploadNFTFiles.buttonDisabled
                        }
                      >
                       Upload images
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Container>
      <NFTImagesPayamentModal 
        show={showPaymentModal}
        handleClose={handlePaymentModalClose}
        singleNft={props.singleNft}
        headerTitle={"Upload Images"}
        generateFunction ={handleFileUpload}
        genrateAction={props.uploadNFTFiles}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  uploadNFTFiles: state.nft.uploadNFTFiles,
  propertiesList: state.nft.nftPropertiesList,
  sampleNftExcel : state.nft.sampleNftExcel,
  singleNft: state.nft.singleNft,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NFTUploadFiles);
