import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TokensDropdown from "../transactions/TokensDropdown";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { fetchNftListStart, deleteNftStart } from "../store/actions/NftAction";
import GeneratorSelectModal from "./GeneratorSelectModal";
import CustomSortBy from "../Helper/CustomSortBy";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import Popover from '@material-ui/core/Popover';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
  custSelect: {
    "& .MuiPaper-root": {
      backgroundColor: "#252525",
      border: "1px solid #3e4e56",
    },
  }
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
  table: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#171717",
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      minWidth: "150px",
      maxWidth: "200px",
    },
    "& .MuiTableCell-stickyHeader:first-child": {
      minWidth: "50px",
      maxWidth: "50px",
    },
    "& .MuiTableCell-body": {
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      maxWidth: "250px",
      overflow: "hidden",
    },
  },
  MuiTablePagination: {
    backgroundColor: "red",
  },
});

const usepaginationStyle = makeStyles({
  MuiTablePagination: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#828282",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
});

const useButtonStyle = makeStyles({
  filterButton: {
    backgroundColor: "transperant",
    color: "#828282",
    borderColor: "#828282",
    zIndex: 1,
    fontWeight: 500,
  },
});

const NftIndex = (props) => {
  const tableclasses = useTableStyles();

  const buttonStyle = useButtonStyle();

  const paginationStyle = usepaginationStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectGeneratorModal, setSelectGeneratorModal] = useState({
    modalFlag: false,
    nft_id: null,
  });

  const [tokenDropdownFlag, setTokenDropdownFlag] = useState(false);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(undefined);

  const [filterToken, setFilterToken] = useState(null);

  useEffect(() => {
    props.dispatch(
      fetchNftListStart({ skip: page * rowsPerPage, take: rowsPerPage })
    );
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.dispatch(
      fetchNftListStart({ skip: page * rowsPerPage, take: rowsPerPage })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    props.dispatch(
      fetchNftListStart({
        skip: page * event.target.value,
        take: event.target.value,
      })
    );
  };

  const deleteNft = (nft_unique_id) => {
    props.dispatch(deleteNftStart({ nft_unique_id: nft_unique_id }));
  };

  const handleGenratorModalOpen = (nft_id) => {
    setSelectGeneratorModal({
      ...selectGeneratorModal,
      nft_id: nft_id,
      modalFlag: true,
    });
  };

  const closeGenratorSelectModal = () => {
    setSelectGeneratorModal({
      ...selectGeneratorModal,
      nft_id: null,
      modalFlag: false,
    });
  };

  const handleTokenDropdown = (event) => {
    setTokenDropdownFlag(true);
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setTokenDropdownFlag(false);
  };

  const handleDropdownChange = (name, address) => {
    setFilterToken(name);
    setTokenDropdownFlag(false);
  };

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    const notificationMessage = getSuccessNotificationMessage(
      "Address copied"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const classes = useStylesBootstrap();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, data) => {
    setAnchorEl({ target: event.currentTarget, data: data });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Contract List</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item active">Contracts</li>
          </ol>
        </section>
        <div className="container-fluid p-4">
          <div className="card-sec-header">
            <h3 className="text-bold m-0 whitecolor">
              Contract List
            </h3>
            <div className="callout mt-3">
              <h4>Notes:</h4>
              <ul>
                <li>Overall list of contracts with detailed description about each contract.</li>
                <li>You should connect your wallet (Metamask or WalletConnect) and make two payments to successfully generate and upload NFTs.</li>
                <li>The second payment must be made to generate and upload the NFT images.</li>
                <li>Once both the payments are successful, you can begin to upload or generate NFT images by filling in the property details.</li>
              </ul>
            </div>
          </div>
          <div className="table-filter-wrapper">
            <div className="table-token-dropwdown">
              <Button
                className={buttonStyle.filterButton}
                onClick={(event) => handleTokenDropdown(event)}
                variant="outlined"
              >
                <span>
                  <i className="fas fa-filter"></i> Sort By{" "}
                  {filterToken != null && <span>: {filterToken}</span>}
                </span>
              </Button>
              {!props.nftList.loading && (
                <CustomSortBy
                  open={tokenDropdownFlag}
                  anchorEl={dropdownAnchorEl}
                  handleClickAway={handleClickAway}
                  placement={"bottom-start"}
                  handleDropdownChange={handleDropdownChange}
                  filterToken={filterToken}
                  sortByheading={"Sort by"}
                  dropdownlist={[
                    { name: "All" },
                    { name: "Deployed contracts" },
                    { name: "Generated contracts" },
                  ]}
                />
              )}
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={props.nftList.loading ? 0 : props.nftList.data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={paginationStyle.MuiTablePagination}
              showFirstButton="true"
              showLastButton="true"
            />
          </div>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={tableclasses.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <span className="text-bold">Contract Name</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Symbol</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Total Supply</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Wallet Address</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Contract Address</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Payment Status</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.nftList.loading ? (
                  <div className="p-3 w-100">
                    <h6 className="white-color text-bold text-center">
                      Loading...
                    </h6>
                  </div>
                ) : (
                  <>
                    {props.nftList.data.nft.length > 0 &&
                      props.nftList.data.nft.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + 1 + index}
                          </TableCell>
                          <TableCell>
                            <p className="text-no-overflow mb-0">
                              {" "}
                              {data.contract_name}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.symbol}</p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.total_supply}</p>
                          </TableCell>
                          <TableCell>
                            <div className="account">
                              <p className="mb-0" onClick={() => copyToClipboard(data.wallet_address)}>
                                {" "}
                                {data.wallet_address
                                  ?
                                  data.wallet_address.substr(0, 5) + "..." +
                                  data.wallet_address.substr(data.wallet_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="account">
                              <p className="mb-0" onClick={() => copyToClipboard(data.contract_address)}>
                                {" "}
                                {data.contract_address
                                  ?
                                  data.contract_address.substr(0, 5) + "..." +
                                  data.contract_address.substr(data.contract_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <span
                              className={`custom-badge single-line-text mb-3 ${data.create_payment_status_formatted.btn_class}`}
                            >
                              {data.create_payment_status_formatted.status}
                            </span>
                            <span
                              className={`custom-badge single-line-text ${data.generate_payment_status_formatted.btn_class}`}
                            >
                              {data.generate_payment_status_formatted.status}
                            </span>
                          </TableCell>
                          <TableCell>

                            <div>
                              <Button aria-describedby={id} variant="contained" className="cust-dropdown" color="primary" onClick={e => handleClick(e, data)}>
                                Action&nbsp;<i className="fas fa-caret-down"></i>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
                {open && <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl.target}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.custSelect}
                >
                  {anchorEl.data.create_payment_status == 0 &&
                    !anchorEl.data.contract_address ? (
                    <>
                      <Link to={`/contract/deploy-contract/${anchorEl.data.nft_unique_id}`}>
                        <div className="cust-dropdown-options">Deploy Contract</div>
                      </Link>
                      <Link to={`/contract/edit-contract/${anchorEl.data.nft_unique_id}`}>
                        <div className="cust-dropdown-options">Edit Contract</div>
                      </Link>
                    </>
                  ) : anchorEl.data.generate_payment_status == 2 ? (
                    <>
                      <div className="cust-dropdown-options"
                        onClick={() => {
                          handleGenratorModalOpen(anchorEl.data.nft_unique_id);
                          handleClose();
                        }}
                      >Generator Select</div>
                      <Link to={`/contract/${anchorEl.data.nft_unique_id}/nft-images-list`}>
                        <div className="cust-dropdown-options">Generated Images</div>
                      </Link>
                    </>
                  ) : (
                    <Link to={`/generate-nft/payment/${anchorEl.data.nft_unique_id}`}>
                      <div className="cust-dropdown-options">Select Generator</div>
                    </Link>
                  )}
                  <div
                    className="cust-dropdown-options"
                    onClick={() => {
                      if (window.confirm("Do you want to delete this Contract?"))
                        deleteNft(anchorEl.data.nft_unique_id);
                      handleClose();
                    }}
                  >Delete Contract</div>
                  {anchorEl.data.create_payment_status == 1 &&
                    anchorEl.data.generate_payment_status == 0 ? (
                    <Link to={`/tokens/create-token/confirmation/${anchorEl.Tabledata.nft_unique_id}`}>
                      <div className="cust-dropdown-options">Generate Nft Payment</div>
                    </Link>
                  ) : (
                    ""
                  )}
                </Popover>}
              </TableBody>
            </Table>
            {!props.nftList.loading && props.nftList.data.nft.length < 0 && (
              <div className="">
                <h6 className="white-color text-bold text-center">
                  No data found
                </h6>
              </div>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={props.nftList.loading ? 0 : props.nftList.data.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={paginationStyle.MuiTablePagination}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </div>
      <GeneratorSelectModal
        show={selectGeneratorModal.modalFlag}
        data={selectGeneratorModal}
        handleClose={closeGenratorSelectModal}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  nftList: state.nft.nftList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NftIndex);
