import React, { Component, useState, useContext } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import NoDataFound from "../NoDataFound/NoDataFound";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translation/en.json";
import pt from "../translation/pt.json";
import DashBoard from "../Dashboard/DashBoard";
import MainLayout from "../Layouts/MainLayout";
import TransactionIdex from "../reports/ReportTransactionIdex";
import ReportTransactionIdex from "../reports/ReportTransactionIdex";
import ReportGainerLoser from "../reports/ReportGainerLoser";
import MarketCapitalization from "../reports/MarketCapitalization";
import CryptoStats from "../reports/CryptoStats";
import CurrencyExchange from "../currencyexchange/CurrencyExchange";
import Transactions from "../transactions/Transactions";
import TransactionSearch from "../transactions/TransactionSearch";
import SingleTransaction from "../transactions/SingleTransaction";
import TranscationCounter from "../transactions/TranscationCounter";
import ProfileIndex from "../profile/ProfileIndex";
import EditProfile from "../profile/EditProfile";
import ChangePassword from "../profile/ChangePassword";
import DeleteAccount from "../profile/DeleteAccount";
import TokenListing from "../token/TokenListing";
import SingleToken from "../token/SingleToken";
import CreateToken from "../token/CreateToken";
import Logout from "../auth/Logout";
import LandingPage from "../landing/LandingPage";
import EmptyLayout from "../Layouts/EmptyLayout";
import StaticPages from "../staticpages/StaticPages";
import LandingCreateToken from "../landing/createtoken/LandingCreateToken";
import CreateTokenAdminComission from "../landing/createtoken/CreateTokenAdminComission";
import CreateTokenConfirmation from "../token/CreateTokenConfirmation";
import DeployContract from "../token/DeployContract";
import GenerateNftPayment from "../token/GenerateNftPayment";
import LandingStaticPages from "../staticpages/LandingStaticPages";
import LogoutLayout from "../Layouts/LogoutLayout";
import LandingCreateTokenPreview from "../landing/createtoken/LandingCreateTokenPreview";
import CreateTokenProcess from "../landing/createtoken/CreateTokenProcess";
import LandingPageIndex from "../NFTLandingPage/LandingPageIndex";
import CreateNFTStep2 from "../NFTLandingPage/CreateNFTStep2";
import CreateNFTStep3 from "../NFTLandingPage/CreateNFTStep3";
import CreateNFTStep4 from "../NFTLandingPage/CreateNFTStep4";
import CreateContract from '../CreateContract/CreateContract'
import EditContract from '../CreateContract/EditContract'
import NftPropertiesIndex from "../CreateContract/NftPropertiesIndex";
import NftIndex from "../CreateContract/NftIndex";
import NFTUploadIndex from "../CreateContract/NFTUploadIndex";
import ListOfNFT from "../nftimages/ListOfNFT";
import SingleNFT from "../nftimages/SingleNFT";
import EditNFT from "../nftimages/EditNFT";
import AddNFTImage from "../nftimages/AddNFTImage";

const history = createHistory();
const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps} {...props}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    setAuthntication(
      userId && accessToken && userLoginStatus == "true" ? true : false
    );
    document.body.scrollTop = 0;
  });

  return (
    <>
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
          sizes="16x16"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.get("configData.site_icon")}
        />
        <meta
          name="description"
          content={configuration.get("configData.meta_description")}
        ></meta>
        <meta
          name="keywords"
          content={configuration.get("configData.meta_keywords")}
        ></meta>
        <meta
          name="author"
          content={configuration.get("configData.meta_author")}
        ></meta>
      </Helmet>
      <Switch>
        {/* <AppRoute
          path={"/"}
          component={LandingPage}
          exact
          layout={EmptyLayout}
        /> */}
        <AppRoute
          path={"/"}
          component={LandingPageIndex}
          exact
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-nft-step2/:nft_unique_id"}
          component={CreateNFTStep2}
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-nft-step3"}
          component={CreateNFTStep3}
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-nft-step4"}
          component={CreateNFTStep4}
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-token"}
          component={LandingCreateToken}
          exact
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-token/preview"}
          component={LandingCreateTokenPreview}
          exact
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-token/creating-token"}
          component={CreateTokenProcess}
          exact
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/create-token/confirmation/:token_unique"}
          component={CreateTokenAdminComission}
          layout={EmptyLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/dashboard"}
          component={DashBoard}
          exact
          layout={MainLayout}
        />
        <AppRoute
          path={"/currency-exchange"}
          component={CurrencyExchange}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/transactions"}
          component={Transactions}
          layout={MainLayout}
        />
        <AppRoute
          path={"/transcations-search"}
          component={TransactionSearch}
          layout={MainLayout}
        />
        <AppRoute
          path={"/transcations-details"}
          component={SingleTransaction}
          layout={MainLayout}
        />
        <AppRoute
          path={"/transcations-counter"}
          component={TranscationCounter}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/profile"}
          exact
          component={ProfileIndex}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/profile/edit-profile"}
          component={EditProfile}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/profile/change-password"}
          component={ChangePassword}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/profile/delete-account"}
          component={DeleteAccount}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/tokens/tokens-list"}
          component={TokenListing}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/tokens/single-token/:token_unique"}
          component={SingleToken}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/tokens/create-token"}
          exact
          component={CreateToken}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/tokens/create-token/confirmation/:token_unique"}
          component={CreateTokenConfirmation}
          layout={MainLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/contract/deploy-contract/:nft_unique_id"}
          component={DeployContract}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/generate-nft/payment/:nft_unique_id"}
          component={GenerateNftPayment}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/logout"}
          component={Logout}
          layout={LogoutLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/pages/:id"}
          component={StaticPages}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/create-contract"}
          exact
          component={CreateContract}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/edit-contract/:nft_unique_id"}
          exact
          component={EditContract}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/nft-properties/:nft_unique_id"}
          component={NftPropertiesIndex}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/nft-files-upload/:nft_unique_id"}
          component={NFTUploadIndex}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/nft-list"}
          component={NftIndex}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/:nft_unique_id/nft-images-list"}
          component={ListOfNFT}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/:nft_unique_id/nft-image/:merge_image_unique_id"}
          component={SingleNFT}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/:nft_unique_id/edit-nft-image/:merge_image_unique_id"}
          component={EditNFT}
          layout={MainLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/contract/:nft_unique_id/add-nft-image"}
          component={AddNFTImage}
          layout={MainLayout}
        />

        <AppRoute
          path={"/home-pages/:id"}
          component={LandingStaticPages}
          layout={EmptyLayout}
        />

        <Route path="*" component={NotFoundIndex} />
      </Switch>
    </>
  );
  }
  
export default App;
