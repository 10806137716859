import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const styles = makeStyles((theme) => ({
  popper: {
    zIndex: 99,
  },
  popperDimensions: {
    padding: "1em",
    width: "200px",
  },
  filterTitleSpacing: {
    marginTop: "5px",
    marginBottom: "15px",
  },
  filterStatusSpacing: {
    marginBottom: "7px",
  },
}));

const CustomSortBy = (props) => {
  const classes = styles();

  return (
    <>
      <Dialog open={props.open} style={{ zIndex: "0" }}>
        <Popper
          placement={props.placement}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "disabled",
            },
          }}
          anchorEl={props.anchorEl}
          id={props.open ? "simple-popper" : undefined}
          open={props.open}
          transition
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={props.handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <div className={classes.popperDimensions}>
                    <Typography variant="h6">{props.sortByheading}</Typography>
                    <FormGroup>
                      <div className="filter__checkboxes">
                        {/* <RadioGroup
                          aria-label="coin"
                          name="coin"
                          value={props.filterToken}
                          onChange={(event) =>
                            // props.handleFilterTokensChange(event.target.name)
                            console.log(event)
                          }
                        > */}
                        {props.dropdownlist.length > 0 &&
                          props.dropdownlist.map((token, index) => (
                            <FormControlLabel
                              key={index}
                              value={token.name}
                              control={
                                <Radio
                                  color="primary"
                                  checked={
                                    token.name == props.filterToken
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    props.handleDropdownChange(
                                      token.name,
                                      token.contract_address
                                    );
                                  }}
                                />
                              }
                              label={token.name}
                            />
                          ))}
                        {/* </RadioGroup> */}
                        {/* <Button
                          onClick={() => props.handleFilterTokensChange(null)}
                        >
                          clear
                        </Button> */}
                      </div>
                    </FormGroup>
                  </div>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Dialog>
    </>
  );
};

export default CustomSortBy;
