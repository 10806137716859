import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import { ColorlibStepIcon } from "../Helper/StepperStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {useStepperStyles} from '../Helper/StepperStyle';
import NFTUploadFiles from "./NFTUploadFiles";
import NFTUploadFilesProperties from "./NFTUploadFilesProperties";
import { connect } from "react-redux";
import { 
  fetchNftPropertiesListStart ,
} from "../store/actions/NftAction";

export const tabs = [
  "Property Details",
  "Files Upload",
];

function getSteps() {
  return tabs;
}

const NFTUploadIndex = (props) => {

  //NOTE:
  // do not change states or manipulate state until fully understand what is doing.
  // you are responsible for the change that are done to states :), happy changing.

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = React.useState({});
  
  const [nftProperties, setNftProperties] = useState([]);

  const [layerName, setLayerName] = useState("");

  const [activeLayer, setActiveLayer] = useState({});

  const classes = useStepperStyles();

  const steps = getSteps();

  const [editProperties, setEditProperties] = useState(null);

  const totalSteps = () => {
    return steps.length;
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  
  
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    const lastStep = activeStep === totalSteps() - 1;

    if (!lastStep) {
      handleNext();
    }
  };

    // Proceed to next step
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if(nftProperties.length <= 1){
      setCompleted({})
     }
  },[nftProperties])

  useEffect(() => {
    props.dispatch(
      fetchNftPropertiesListStart({
        nft_unique_id: props.match.params.nft_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!props.propertiesList.loading) {
      if (props.propertiesList.data.nft_properties.length > 0) {
        let newData = props.propertiesList.data.nft_properties.map(
          (data, index) => {
              return { ...data , nft_images : data.nft_images.map((images , imageindex) => { return {...images , index : imageindex}}), index};
          }
        );
        setNftProperties(newData);
        setActiveLayer(newData[0]);
      }
    }
  }, [props.propertiesList.data]);

  useEffect(() => {
    if (!props.deleteNftProperties.loading) {
      if (props.deleteNftProperties.successData.nft_properties.length > 0) {
      //Note :dont use response data , it delets unsaved layers
      
        let newData = nftProperties.filter(
          (data, index) => {
            if(data.index != activeLayer.index){
              return { ...data, index };
            } 
          }
        );
        setNftProperties(newData);
        setActiveLayer({});
      }else{
        setNftProperties([])
        setActiveLayer({})
      }
    }
  }, [props.deleteNftProperties.successData]);

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <NFTUploadFilesProperties
            handleComplete={handleComplete}
            nftProperties={nftProperties}
            setNftProperties={setNftProperties}
            layerName={layerName}
            setLayerName={setLayerName}
            activeLayer={activeLayer}
            setActiveLayer={setActiveLayer}
            setEditProperties={setEditProperties}
            editProperties={editProperties}
            completed={completed}
            setCompleted={setCompleted}
            {...props}
          />
        );

      case 1:
        return (
          <NFTUploadFiles
            handleComplete={handleComplete}
            handleBack={handleBack}
            {...props}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Contract</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Contracts</a>
            </li>
            <li className="breadcrumb-item active">Contract</li>
          </ol>
        </section>
        <div className="container p-4 container-full-height">
          <div className="custom-box-nomargin w-100">
            {activeStep === tabs.length ? (
              "sucess"
            ) : (
              <Box style={{ margin: "30px 0 50px" }} className={classes.root}>
                <Stepper
                  activeStep={activeStep}
                  style={{ margin: "30px 0 15px" }}
                  alternativeLabel
                  nonLinear
                  connector={false}
                >
                  {tabs.map((tab, index) => (
                    <Step
                      key={tab}
                      disabled= {nftProperties.length <= 1 ? true : false}
                    >
                      <StepButton
                        onClick={handleStep(index)}
                        completed={completed[index]}
                      >
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {tab}
                        </StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                {handleSteps(activeStep)}
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  uploadNFTFiles: state.nft.uploadNFTFiles,
  propertiesList: state.nft.nftPropertiesList,
  deleteNftProperties: state.nft.deleteNftProperties,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NFTUploadIndex);
