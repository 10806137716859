import {
  FETCH_NFT_LIST_START,
  FETCH_NFT_LIST_SUCCESS,
  FETCH_NFT_LIST_FAILURE,
  FETCH_SINGLE_NFT_START,
  FETCH_SINGLE_NFT_SUCCESS,
  FETCH_SINGLE_NFT_FAILURE,
  SAVE_NFT_START,
  SAVE_NFT_SUCCESS,
  SAVE_NFT_FAILURE,
  DELETE_NFT_START,
  DELETE_NFT_SUCCESS,
  DELETE_NFT_FAILURE,
  FETCH_NFT_PROPERTIES_LIST_START,
  FETCH_NFT_PROPERTIES_LIST_SUCCESS,
  FETCH_NFT_PROPERTIES_LIST_FAILURE,
  SAVE_NFT_PROPERTIES_START,
  SAVE_NFT_PROPERTIES_SUCCESS,
  SAVE_NFT_PROPERTIES_FAILURE,
  DELETE_NFT_PROPERTIES_START,
  DELETE_NFT_PROPERTIES_SUCCESS,
  DELETE_NFT_PROPERTIES_FAILURE,
  DELETE_NFT_IMAGES_START,
  DELETE_NFT_IMAGES_SUCCESS,
  DELETE_NFT_IMAGES_FAILURE,
  DEPLOY_CONTRACT_SAVE_START,
  DEPLOY_CONTRACT_SAVE_SUCCESS,
  DEPLOY_CONTRACT_SAVE_FAILURE,
  NFT_GENERATE_PAYMENT_START,
  NFT_GENERATE_PAYMENT_SUCCESS,
  NFT_GENERATE_PAYMENT_FAILURE,
  UPLOAD_NFT_FILES_START,
  UPLOAD_NFT_FILES_SUCCESS,
  UPLOAD_NFT_FILES_FAILURE,
  EXPORT_SAMPLE_NFT_START,
  EXPORT_SAMPLE_NFT_SUCCESS,
  EXPORT_SAMPLE_NFT_FAILURE,
  SEND_GENERATE_REQUEST_START,
  SEND_GENERATE_REQUEST_SUCCESS,
  SEND_GENERATE_REQUEST_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  nftList: {
    data: {},
    loading: true,
    error: false,
  },
  singleNft: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  saveNft: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  deleteNft: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  nftPropertiesList: {
    inputData: {},
    data: {},
    loading: true,
    error: false,
  },
  saveNftProperties: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  deleteNftProperties: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  deleteNftImages: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  deployContractSave: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  nftGeneratePaymentSave: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  uploadNFTFiles: {
    data: {},
    loading: false,
    error: false,
    buttonDisabled: false,
    loadingButttonContent: null,
  },
  sampleNftExcel : {
    data: {},
    loading: false,
    error: false,
    buttonDisabled: false,
    loadingButttonContent: null,
  },
  generateRequest: {
    inputData: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
};

const nftReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NFT_LIST_START:
      return {
        ...state,
        nftList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_LIST_SUCCESS:
      return {
        ...state,
        nftList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_LIST_FAILURE:
      return {
        ...state,
        nftList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_NFT_START:
      return {
        ...state,
        singleNft: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case FETCH_SINGLE_NFT_SUCCESS:
      return {
        ...state,
        singleNft: {
          inputData: {},
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case FETCH_SINGLE_NFT_FAILURE:
      return {
        ...state,
        singleNft: {
          inputData: {},
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case SAVE_NFT_START:
      return {
        ...state,
        saveNft: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_NFT_SUCCESS:
      return {
        ...state,
        saveNft: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_NFT_FAILURE:
      return {
        ...state,
        saveNft: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case DELETE_NFT_START:
      return {
        ...state,
        deleteNft: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case DELETE_NFT_SUCCESS:
      return {
        ...state,
        deleteNft: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case DELETE_NFT_FAILURE:
      return {
        ...state,
        deleteNft: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_START:
      return {
        ...state,
        nftPropertiesList: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_SUCCESS:
      return {
        ...state,
        nftPropertiesList: {
          inputData: {},
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_FAILURE:
      return {
        ...state,
        nftPropertiesList: {
          inputData: {},
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SAVE_NFT_PROPERTIES_START:
      return {
        ...state,
        saveNftProperties: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_NFT_PROPERTIES_SUCCESS:
      return {
        ...state,
        saveNftProperties: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_NFT_PROPERTIES_FAILURE:
      return {
        ...state,
        saveNftProperties: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case DELETE_NFT_PROPERTIES_START:
      return {
        ...state,
        deleteNftProperties: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case DELETE_NFT_PROPERTIES_SUCCESS:
      return {
        ...state,
        deleteNftProperties: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case DELETE_NFT_PROPERTIES_FAILURE:
      return {
        ...state,
        deleteNftProperties: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case DELETE_NFT_IMAGES_START:
      return {
        ...state,
        deleteNftImages: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case DELETE_NFT_IMAGES_SUCCESS:
      return {
        ...state,
        deleteNftImages: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case DELETE_NFT_IMAGES_FAILURE:
      return {
        ...state,
        deleteNftImages: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case DEPLOY_CONTRACT_SAVE_START:
      return {
        ...state,
        deployContractSave: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case DEPLOY_CONTRACT_SAVE_SUCCESS:
      return {
        ...state,
        deployContractSave: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case DEPLOY_CONTRACT_SAVE_FAILURE:
      return {
        ...state,
        deployContractSave: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case NFT_GENERATE_PAYMENT_START:
      return {
        ...state,
        nftGeneratePaymentSave: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case NFT_GENERATE_PAYMENT_SUCCESS:
      return {
        ...state,
        nftGeneratePaymentSave: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case NFT_GENERATE_PAYMENT_FAILURE:
      return {
        ...state,
        nftGeneratePaymentSave: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };
    case UPLOAD_NFT_FILES_START:
      return {
        ...state,
        uploadNFTFiles: {
          data: {},
          loading: true,
          error: false,
          buttonDisabled: true,
          loadingButttonContent: "Uploading... Please wait",
        },
      };
    case UPLOAD_NFT_FILES_SUCCESS:
      return {
        ...state,
        uploadNFTFiles: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };
    case UPLOAD_NFT_FILES_FAILURE:
      return {
        ...state,
        uploadNFTFiles: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };

      case EXPORT_SAMPLE_NFT_START:
        return {
          ...state,
          sampleNftExcel: {
            data: {},
            loading: true,
            error: false,
            buttonDisabled: true,
            loadingButttonContent: "Uploading... Please wait",
          },
        };
      case EXPORT_SAMPLE_NFT_SUCCESS:
        return {
          ...state,
          sampleNftExcel: {
            data: action.data,
            loading: false,
            error: false,
            buttonDisabled: false,
            loadingButttonContent: null,
          },
        };
      case EXPORT_SAMPLE_NFT_FAILURE:
        return {
          ...state,
          sampleNftExcel: {
            data: {},
            loading: false,
            error: action.data,
            buttonDisabled: false,
            loadingButttonContent: null,
          },
        };
      case SEND_GENERATE_REQUEST_START:
        return {
          ...state,
          generateRequest: {
            inputData: action.data,
            loading: true,
            error: false,
            buttonDisable: true,
            loadingButtonContent: "Processing...",
            successData: {},
          },
        };
      case SEND_GENERATE_REQUEST_SUCCESS:
        return {
          ...state,
          generateRequest: {
            inputData: {},
            loading: false,
            error: false,
            buttonDisable: false,
            loadingButtonContent: null,
            successData: action.data,
          },
        };
      case SEND_GENERATE_REQUEST_FAILURE:
        return {
          ...state,
          generateRequest: {
            inputData: {},
            loading: false,
            error: action.error,
            buttonDisable: false,
            loadingButtonContent: null,
            successData: {},
          },
        };

    default:
      return state;
  }
};

export default nftReducer;
