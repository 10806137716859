import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchNftListStart,
  fetchNftListSuccess,
  fetchNftListFailure,
  fetchSingleNftSuccess,
  fetchSingleNftFailure,
  saveNftSuccess,
  saveNftFailure,
  deleteNftSuccess,
  deleteNftFailure,
  fetchNftPropertiesListSuccess,
  fetchNftPropertiesListFailure,
  saveNftPropertiesSuccess,
  saveNftPropertiesFailure,
  deleteNftPropertiesSuccess,
  deleteNftPropertiesFailure,
  deleteNftImagesSuccess,
  deleteNftImagesFailure,
  deployContractSaveSuccess,
  deployContractSaveFailure,
  nftGeneratePaymentSaveSuccess,
  nftGeneratePaymentSaveFailure,
  uploadNFTFilesSuccess,
  uploadNFTFilesFailure,
  exportSampleNftSuccess,
  exportSampleNftFailure,
  sendGenerateRequestSuccess,
  sendGenerateRequestFailure,
} from "../actions/NftAction";

import api from "../../Environment";
import {
  FETCH_NFT_LIST_START,
  FETCH_SINGLE_NFT_START,
  SAVE_NFT_START,
  DELETE_NFT_START,
  FETCH_NFT_PROPERTIES_LIST_START,
  SAVE_NFT_PROPERTIES_START,
  DELETE_NFT_PROPERTIES_START,
  DELETE_NFT_IMAGES_START,
  DEPLOY_CONTRACT_SAVE_START,
  NFT_GENERATE_PAYMENT_START,
  UPLOAD_NFT_FILES_START,
  EXPORT_SAMPLE_NFT_START,
  SEND_GENERATE_REQUEST_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* getNftListAPI(action) {
  try {
    const response = yield api.postMethod("nfts_list" , action.data);

    if (response.data.success) {
      yield put(fetchNftListSuccess(response.data.data));
    } else {
      yield put(fetchNftListFailure(response.data.error));
      yield put(checkLogoutStatus(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNftListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleNftApi() {
  try {
   const inputData = yield select((state) => state.nft.singleNft.inputData);
   const response = yield api.postMethod(
    "nfts_view",
    inputData
   );
   if (response.data.success) {
    yield put(fetchSingleNftSuccess(response.data.data));
   } else {
    yield put(fetchSingleNftFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(fetchSingleNftFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

function* saveNftApi() {
  try {
   const inputData = yield select((state) => state.nft.saveNft.inputData);
   const response = yield api.postMethod(
    "nfts_save",
    inputData
   );
   if (response.data.success) {
    yield put(saveNftSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
    window.location.assign(`/contract/deploy-contract/${response.data.data.nft_unique_id}`);
   } else {
    yield put(saveNftFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(saveNftFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* deleteNftApi() {
  try {
   const inputData = yield select((state) => state.nft.deleteNft.inputData);
   const response = yield api.postMethod(
    "nfts_delete",
    inputData
   );
   if (response.data.success) {
    yield put(deleteNftSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
    yield put(fetchNftListStart());
   } else {
    yield put(deleteNftFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(deleteNftFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* getNftPropertiesListAPI() {
  try {
    const inputData = yield select((state) => state.nft.nftPropertiesList.inputData);
    const response = yield api.postMethod("nft_properties_list", inputData);

    if (response.data.success) {
      yield put(fetchNftPropertiesListSuccess(response.data.data));
    } else {
      yield put(fetchNftPropertiesListFailure(response.data.error));
      yield put(checkLogoutStatus(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNftPropertiesListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveNftPropertiesApi(action) {
  try {
   const inputData = yield select((state) => state.nft.saveNftProperties.inputData);
   const response = yield api.postMethod("nft_properties_save",action.data);
   if (response.data.success) {
    yield put(saveNftPropertiesSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
   } else {
    yield put(saveNftPropertiesFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(saveNftPropertiesFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* deleteNftPropertiesApi() {
  try {
   const inputData = yield select((state) => state.nft.deleteNftProperties.inputData);
   const response = yield api.postMethod("nft_properties_delete",inputData);
   if (response.data.success) {
    yield put(deleteNftPropertiesSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
   } else {
    yield put(deleteNftPropertiesFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(deleteNftPropertiesFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* deleteNftImagesApi() {
  try {
   const inputData = yield select((state) => state.nft.deleteNftImages.inputData);
   const response = yield api.postMethod("nft_images_delete",inputData);
   if (response.data.success) {
    yield put(deleteNftImagesSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
   } else {
    yield put(deleteNftImagesFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(deleteNftImagesFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* deployContractSaveApi(action) {
  try {
   const inputData = yield select((state) => state.nft.deployContractSave.inputData);
   const response = yield api.postMethod("deploy_contract_save",action.data);
   if (response.data.success) {
    yield put(deployContractSaveSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
    window.location.assign(`/contract/nft-list`);
   } else {
    yield put(deployContractSaveFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(deployContractSaveFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* nftGeneratePaymentSaveApi(action) {
  try {
   const inputData = yield select((state) => state.nft.nftGeneratePaymentSave.inputData);
   const response = yield api.postMethod("nft_generate_payment_save",action.data);
   if (response.data.success) {
    yield put(nftGeneratePaymentSaveSuccess(response.data.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
    window.location.assign(`/contract/nft-list`);
   } else {
    yield put(nftGeneratePaymentSaveFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(nftGeneratePaymentSaveFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

 function* UploadNFTFilesAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_excel_import_save", action.data
    );
    if (response.data.success) {
      yield put(uploadNFTFilesSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(`/contract/${response.data.data.nft_unique_id}/nft-images-list`);
    } else {
      yield put(uploadNFTFilesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(uploadNFTFilesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* exportSampleNftAPI(action) {
  try {
    const response = yield api.postMethod(
      "export_sample_nft", action.data
    );
    if (response.data.success) {
      yield put(exportSampleNftSuccess(response.data.data));
    } else {
      yield put(exportSampleNftFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(exportSampleNftFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendGenerateRequestApi(action) {
  try {
   const inputData = yield select((state) => state.nft.generateRequest.inputData);
   const response = yield api.postMethod("nft_generate_request_save",action.data);
   if (response.data.success) {
    yield put(sendGenerateRequestSuccess(response.data));
    const notificationMessage = getSuccessNotificationMessage(
     response.data.message
    );
    yield put(createNotification(notificationMessage));
   } else {
    yield put(sendGenerateRequestFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
     response.data.error
    );
    yield put(createNotification(notificationMessage));
   }
  } catch (error) {
   yield put(sendGenerateRequestFailure(error));
   const notificationMessage = getErrorNotificationMessage(
    error.response.data.error
   );
   yield put(createNotification(notificationMessage));
  }
 }

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_NFT_LIST_START, getNftListAPI),
    yield takeLatest(FETCH_SINGLE_NFT_START, fetchSingleNftApi),
    yield takeLatest(SAVE_NFT_START, saveNftApi),
    yield takeLatest(DELETE_NFT_START, deleteNftApi),
    yield takeLatest(FETCH_NFT_PROPERTIES_LIST_START, getNftPropertiesListAPI),
    yield takeLatest(SAVE_NFT_PROPERTIES_START, saveNftPropertiesApi),
    yield takeLatest(DELETE_NFT_PROPERTIES_START, deleteNftPropertiesApi),
    yield takeLatest(DELETE_NFT_IMAGES_START, deleteNftImagesApi),
    yield takeLatest(DEPLOY_CONTRACT_SAVE_START, deployContractSaveApi),
    yield takeLatest(NFT_GENERATE_PAYMENT_START, nftGeneratePaymentSaveApi),
    yield takeLatest(UPLOAD_NFT_FILES_START, UploadNFTFilesAPI),
    yield takeLatest(EXPORT_SAMPLE_NFT_START, exportSampleNftAPI),
    yield takeLatest(SEND_GENERATE_REQUEST_START, sendGenerateRequestApi),
  ]);
}
