import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchUserDetailsStart } from "../store/actions/UserAction";
import { connect } from "react-redux";

const ProfileIndex = (props) => {
  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>Profile</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Profile</a>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {props.profile.loading ? (
                "loading..."
              ) : (
                <div className="col-lg-6 mb-md-3">
                  <div className="wallet-card p-4 pt-50 text-center">
                    <div>
                      <a
                        className="avatar avatar-xxl status-success mb-3"
                        href="#"
                      >
                        <img
                          src={
                            props.profile.data.picture !== null
                              ? props.profile.data.picture
                              : window.location.origin +
                              "/assets/images/avatar/9.jpg"
                          }
                          alt="..."
                        />
                      </a>
                    </div>
                    <h5 className="">
                      <a class="text-default hover-primary" href="#">
                        {props.profile.data.name}
                      </a>
                    </h5>
                    {/* <p><small className="font-size-12">Designer</small></p> */}
                    <div className="profile-info my-4">
                      {props.profile.data.about != "" && props.profile.data.about != null ?
                        <p>{props.profile.data.about}</p> : null
                      }
                      {props.profile.data.mobile != "" && (
                        <h5 className="text-bold whitecolor text-left">
                          Mobile :{" "}
                          <span className="text-muted">
                            {props.profile.data.mobile}
                          </span>
                        </h5>
                      )}
                      <h5 className="text-bold whitecolor text-left">
                        Mail :{" "}
                        <span className="text-muted">
                          {props.profile.data.email}
                        </span>
                      </h5>
                      {/* <h5 className="text-bold whitecolor text-left">Occupation : <span  className="text-muted">work</span></h5> */}
                    </div>
                    <ul className="profile-action-wrapper flex-justified text-center p-20">
                      <li className="br-1">
                        <Link
                          className="btn btn-primary withTheme w-100"
                          to="/profile/edit-profile"
                        >
                          Edit Profile
                        </Link>
                      </li>
                      <li className="br-1">
                        <Link
                          className="btn btn-primary withTheme w-100"
                          to="/profile/change-password"
                        >
                          Change Password
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="btn btn-primary withTheme w-100"
                          to="/profile/delete-account"
                        >
                          Delete Account
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="col-lg-6">
                <div
                  className="wallet-card p-4 hide hidden"
                  style={{ visibility: "hidden" }}
                >
                  <h3 className="mt-3 whitecolor text-bold">
                    Wallet Information
                  </h3>
                  <h4 className="text-bold whitecolor my-3">
                    Address :{" "}
                    <span className="text-muted">
                      2dxg25454841132457d21564d1321d35d5zxd54d5
                    </span>
                  </h4>
                  <h4 className="text-bold whitecolor mb-3">
                    Balance : <span className="text-muted">100 BTC</span>
                  </h4>
                  <button
                    className="btn btn-primary withTheme mt-4 inline-button"
                    type="button"
                  >
                    Add Token
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
