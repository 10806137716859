import React, { useContext, useEffect, useState } from "react";
import { authContext } from "./authprovider/AuthProvider";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { CHAIN_INFO } from "./authprovider/chains";

const AccountModal = (props) => {
  const { status, handleClose } = props;

  const { auth, hanldeLogout } = useContext(authContext);

  const dispatch = useDispatch();

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    const notificationMessage = getSuccessNotificationMessage(
      "Wallet address copied"
    );
    dispatch(createNotification(notificationMessage));
  };

  return (
    <>
      <Modal
        show={status}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="account-details-modal"
      >
        <Modal.Body className="connect-wallet-modal-body">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  no-padding ">
            <div className="headerwrapper">
              <h2 className="text-center text-capitalize primary-text">
                Account Details
              </h2>

              <div className="modal-close" onClick={() => handleClose()}>
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
            <div className="custom-hr"></div>
            <div className="account-details-wrapper">
              <div className="account-details">
                <div className="wallet-address">
                  <h4>
                    {auth.accounts.substr(0, 15)}...
                    {auth.accounts.substr(auth.accounts.length - 4)}
                  </h4>
                  <div onClick={() => copyToClipboard(auth.accounts)}>
                    <p className="copyu">
                      <i className="far fa-copy"></i>
                    </p>
                  </div>
                </div>
                <div className="balance-details">
                  <div className="token">
                    <h4>Balance :</h4>
                    <h4>
                      {Number(auth.ethBalance).toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                      })}{" "}
                      <span className="denomination">
                      {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}{" "}
                      </span>
                      
                    </h4>
                  </div>
                </div>
                <div className=" text-center">
                  <button
                    className="btn btn-danger withTheme"
                    onClick={() => hanldeLogout()}
                  >
                    logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountModal;
